/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'
import {useTranslation} from 'react-i18next'

const HeaderUserMenu: FC = () => {
  //const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const {t} = useTranslation('translation')
  const getRedirectPath = () => {
    if (Cookies.get('name') === 'whale') {
      return '/auth/login?plan=whale'
    } else if (Cookies.get('name') === 'toro') {
      return '/auth/login?plan=toro'
    } else {
      return '/auth/login'
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              style={{objectFit: 'cover'}}
              alt='Logo'
              src={userInfo?.data?.profile?.data?.profile_photo_path}
            />
          </div>

          <div className='d-flex flex-column overflow-hidden'>
            <div
              className='fw-bolder d-flex align-items-center fs-5 w-100 overflow-hidden'
              style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {userInfo?.data?.name} {userInfo?.data?.last_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a
              href='#'
              className='fw-bold text-muted text-hover-primary fs-7 w-100 overflow-hidden'
              style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {userInfo?.data?.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/pages/profile'} className='menu-link px-5'>
          {t('My Profile')}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/pages/profile/appsumo'} className='menu-link px-5'>
          {t('Redeem coupon')}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            localStorage.removeItem('access_token')
            sessionStorage.removeItem('normal_close_onboarding')
            window.location.href = getRedirectPath()
          }}
          className='menu-link px-5'
        >
          {t('Sign Out')}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
