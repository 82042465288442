import React from 'react'
import {useTranslation} from 'react-i18next'
import RadioInputSection from '../RadioInputSection'

function ProtectedPostWrapper({
  selectedValues,
  wordpressBlogDetail,
  categories,
  newCategories,
  onSelectedValues,
  onNewCategories,
}: any) {
  const {t} = useTranslation('translation')

  const defaultChecked = (e: any, category: any) => {
    if (
      selectedValues &&
      selectedValues.categories !== null &&
      selectedValues.categories !== undefined
    ) {
      return Object.keys(selectedValues[e]).find((x: any) => x === category?.id.toString()) !==
        undefined
        ? true
        : false
    }
    return false
  }

  const addNewCategories = (category: any) => {
    if (newCategories.find((x: any) => x.id === category.id) !== undefined) {
      const currentCategory = newCategories.filter((y: any) => y.id !== category.id)
      onNewCategories(currentCategory)
    } else {
      onNewCategories([...newCategories, category])
    }
  }

  return (
    <div className='border-bottom border-secondary mb-4 pb-4'>
      <h4>{t('Which posts should we protect?')}</h4>
      <RadioInputSection
        title={t('All public')}
        name='fetch_type'
        checked={selectedValues && selectedValues['fetch_type'] === 'all' && true}
        onChange={() => {
          onSelectedValues({...selectedValues, fetch_type: 'all'})
        }}
      />
      <RadioInputSection
        title={t('Only posts with #toroprotect tag')}
        name='fetch_type'
        checked={selectedValues && selectedValues['fetch_type'] === 'tag' && true}
        onChange={() => {
          onSelectedValues({...selectedValues, fetch_type: 'tag'})
        }}
      />
      {wordpressBlogDetail?.type === 'wordpress' && (
        <RadioInputSection
          title={t('Only from specific categories')}
          name='fetch_type'
          checked={selectedValues && selectedValues['fetch_type'] === 'categories' && true}
          onChange={() => {
            onSelectedValues({...selectedValues, fetch_type: 'categories'})
          }}
        />
      )}
      {selectedValues && selectedValues['fetch_type'] === 'categories' && (
        <div className='d-flex flex-wrap mt-3'>
          {categories.map((category: any, idx: any) => {
            return (
              <label
                key={idx}
                className='form-check form-switch form-check-custom form-check-solid me-2 mb-2'
                style={{minWidth: '200px'}}
              >
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  defaultChecked={defaultChecked('categories', category as any)}
                  name={category?.name}
                  onChange={() => {
                    addNewCategories(category)
                  }}
                />
                <span className='form-check-label text-muted fs-7'>{category?.name}</span>
              </label>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProtectedPostWrapper
