import React, {useState, useEffect} from 'react'

import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router'

import Swal from 'sweetalert2'
import {fetchConfig} from '../../../../../../../setup/reduxslice/slices/config'

import Uploader from '../../../../../file-manager/components/Uploader'
import ButtonWrapper from '../ButtonWrapper'
import SuccessModal from './components/SuccessModal'

interface IFileUploadActionProps {
  isItInvalidPlan?: boolean
}

function FileUploadAction({isItInvalidPlan}: IFileUploadActionProps) {
  const {t} = useTranslation('translation')
  const [showUploader, setShowUploader] = useState<boolean>(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
  const configStatus = useSelector((state: any) => state.config.status)
  const userInfo: any = useSelector((state: any) => state.user.userInfo)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (configStatus === 'idle') {
      dispatch(fetchConfig())
    }
  })

  const handleUploadFiles = () => {
    const remains = userInfo?.data?.praxis?.data?.limits?.copyright?.can?.items?.remains
    if (remains === 0) {
      Swal.fire({
        title: !isItInvalidPlan
          ? t("Your current plan doesn't support more file uploads")
          : t('You dont have a active plan'),
        text: t('Please consider upgrading your plan.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Go to plans'),
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: '/pages/profile/plans',
          })
        } else {
          setShowUploader(false)
        }
      })
    } else {
      setShowUploader(true)
    }
    setShowUploader(true)
  }

  return (
    <div className='d-flex justify-content-between w-100 align-items-center'>
      <ButtonWrapper
        id='plan_copyright'
        planName='My Uploads'
        title='Upload New File'
        icon='duotune/files/fil018.svg'
        onCreateAccount={handleUploadFiles}
      />
      {showUploader && (
        <Uploader
          setShowUploader={(e: any) => {
            setShowUploader(e)
          }}
          showUploader={showUploader}
          onUploadSuccess={() => {
            setShowUploader(false)
            setIsSuccessModalOpen(true)
          }}
        />
      )}
      <SuccessModal isOpen={isSuccessModalOpen} onIsOpen={setIsSuccessModalOpen} />
    </div>
  )
}

FileUploadAction.defaultProps = {
  isItInvalidPlan: false,
}

export default FileUploadAction
