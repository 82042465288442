import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {getPlanDetail} from '../../../Helpers'

interface IMiniPlanCardProps {
  currentPlans: any
  planName: string
}

function MiniPlanCard({currentPlans, planName}: IMiniPlanCardProps) {
  const {t} = useTranslation('translation')

  const history = useHistory()
  const goToPlanPage = (id: any) => {
    history.push('/pages/profile/plans', {id: id})
  }

  return (
    <div className='col bg-light rounded-2 d-flex flex-column justify-content-between p-2 border'>
      <div className='fs-5 fw-bolder text-capitalize'>
        {t(`${getPlanDetail(currentPlans, planName)?.title}`)}
      </div>
      {currentPlans &&
        currentPlans.limits[planName] &&
        !currentPlans?.limits[planName].is_trial && (
          <div className='d-flex align-items-center justify-content-start'>
            {getPlanDetail(currentPlans, planName)?.dataInfo}
          </div>
        )}
      {(currentPlans?.limits[planName]?.is_trial ||
        currentPlans?.limits[planName]?.can?.items?.remains === 0) && (
        <div role='button' onClick={() => goToPlanPage(getPlanDetail(currentPlans, planName)?.id)}>
          <div className='fs-[8px] fw-bolder text-hover-success'>
            <ins>{t('Upgrade Plan')}</ins>
          </div>
        </div>
      )}
    </div>
  )
}

export default MiniPlanCard
