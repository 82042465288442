import React from 'react'
import ProfileCardActions from './components/ProfileCardActions'
import ProfileCardHeader from './components/ProfileCardHeader'
import ProfileCardPlans from './components/ProfileCardPlans'

interface IProfileDetailsProps {
  userDetails: any
}

function ProfileCard({userDetails}: IProfileDetailsProps) {
  return (
    <div className='card'>
      <div className='card-body d-flex flex-column justify-content-center align-items-center gap-5'>
        <ProfileCardHeader userDetails={userDetails} />
        <ProfileCardPlans userDetails={userDetails} />
        <ProfileCardActions />
      </div>
    </div>
  )
}

export default ProfileCard
