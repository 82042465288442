import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../../../../_metronic/helpers'

function HelperModal({details, isOpen, onClose}: any) {
  const {t} = useTranslation('translation')
  return (
    <Modal
      id='move-to-folder-modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={isOpen}
      onHide={onClose}
    >
      <div className='container-xl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-between border-0'>
          <h3>{details?.title}</h3>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={onClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body'>
          <div style={{margin: '0 auto', textAlign: 'center'}}>
            <iframe
              style={{width: '100%', minHeight: '400px'}}
              src={details?.link}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
          <br></br>
          <button
            type='button'
            className='btn btn-primary'
            style={{float: 'right'}}
            onClick={onClose}
          >
            {t('Close')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default HelperModal
