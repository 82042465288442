import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'
import '../index.scss'
import {useTranslation} from 'react-i18next'

export default function InstaTokenModal({isOpen, link, onClose}: any) {
  const {t} = useTranslation('translation')
  const goToPlanPage = () => {
    window.location.replace(link)
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={isOpen}
      onHide={() => {
        onClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header d-flex justify-content-between p-0 border-0'>
          <h3>{t('Instagram account')}</h3>
          <div
            className='btn btn-icon btn-sm btn-light-primary'
            onClick={() => {
              onClose()
            }}
          >
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div id='account_post-modal' className='h-25'>
          <span className='fw-bold fs-4 text-dark me-2 w-100 text-center'>
            {t('To refresh the connection token')}.{' '}
            <span className='text-hover-primary' role='button' onClick={() => goToPlanPage()}>
              <ins>{t('click here')}.</ins>
            </span>
          </span>
        </div>
      </div>
    </Modal>
  )
}
