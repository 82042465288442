import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import ShowPassword from '../../../../../../modules/auth/components/ShowPassword'

function QuickBlogInputSection({title, name, pass, passDeactive, onChange, onPassDeactive}: any) {
  return (
    <div>
      <span className='fw-bold fs-4 text-dark me-2 w-50 mt-4'>{title}</span>
      <div className='d-flex align-items-center gap-2'>
        <input
          type={!passDeactive && pass ? 'password' : 'text'}
          className='form-control form-control-lg form-control-solid border border-secondary mb-3'
          name={name}
          onChange={onChange}
        />
        {pass && (
          <ShowPassword
            passwordInputType={!passDeactive && pass ? 'password' : 'text'}
            onPasswordInputType={onPassDeactive}
          />
        )}
      </div>
    </div>
  )
}

function WordpressIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      fill='#009EF7'
      className='bi bi-wordpress'
      viewBox='0 0 16 16'
    >
      <path d='M12.633 7.653c0-.848-.305-1.435-.566-1.892l-.08-.13c-.317-.51-.594-.958-.594-1.48 0-.63.478-1.218 1.152-1.218.02 0 .039.002.058.003l.031.003A6.838 6.838 0 0 0 8 1.137 6.855 6.855 0 0 0 2.266 4.23c.16.005.313.009.442.009.717 0 1.828-.087 1.828-.087.37-.022.414.521.044.565 0 0-.371.044-.785.065l2.5 7.434 1.5-4.506-1.07-2.929c-.369-.022-.719-.065-.719-.065-.37-.022-.326-.588.043-.566 0 0 1.134.087 1.808.087.718 0 1.83-.087 1.83-.087.37-.022.413.522.043.566 0 0-.372.043-.785.065l2.48 7.377.684-2.287.054-.173c.27-.86.469-1.495.469-2.046zM1.137 8a6.864 6.864 0 0 0 3.868 6.176L1.73 5.206A6.837 6.837 0 0 0 1.137 8z' />
      <path d='M6.061 14.583 8.121 8.6l2.109 5.78c.014.033.03.064.049.094a6.854 6.854 0 0 1-4.218.109zm7.96-9.876c.03.219.047.453.047.706 0 .696-.13 1.479-.522 2.458l-2.096 6.06a6.86 6.86 0 0 0 2.572-9.224z' />
      <path
        fillRule='evenodd'
        d='M0 8c0-4.411 3.589-8 8-8 4.41 0 8 3.589 8 8s-3.59 8-8 8c-4.411 0-8-3.589-8-8zm.367 0c0 4.209 3.424 7.633 7.633 7.633 4.208 0 7.632-3.424 7.632-7.633C15.632 3.79 12.208.367 8 .367 3.79.367.367 3.79.367 8z'
      />
    </svg>
  )
}

const getSelectedBlogIcon = (newBlogForm: any) => {
  if (newBlogForm === 'wordpress') {
    return <WordpressIcon />
  } else if (newBlogForm === 'blogspot') {
    return <i className='fab fa-blogger fa-2x'></i>
  } else if (newBlogForm === 'quickblog') {
    return (
      <img
        style={{width: '2rem'}}
        src={toAbsoluteUrl('/media/copyrightsworld/side-logo.png')}
        alt='img'
      />
    )
  }
}

function NewBlogWrapper({
  userInfo,
  newBlogForm,
  BEData,
  onAddNewBlogDetail,
  onPluginInstall,
  onAddQuickblog,
  onAddNewBlockCallBack,
}: any) {
  const {t} = useTranslation('translation')
  const [quickBlogData, setQuickBlogData] = useState({})
  const [passDeactive, setPassDeactive] = useState(false)

  const onChangeQuickBlock = (e: any) => {
    let currentData: any = {...quickBlogData}
    if (e.target.name === 'account_id') {
      currentData[e.target.name] = Number(e.target.value)
    } else {
      currentData[e.target.name] = e.target.value
    }
    setQuickBlogData(currentData)
  }

  return (
    <div className='card w-100'>
      <div className='d-flex flex-column w-100'>
        <div className='w-100 d-flex justify-content-start'>{getSelectedBlogIcon(newBlogForm)}</div>
        <div className='d-flex align-items-start w-100 mb-5'>
          {BEData?.msg ? (
            <input
              type='text'
              className='form-control form-control-lg form-control-solid border border-secondary'
              name='wordpressUrl'
              value={BEData?.mainUrl}
              disabled
            />
          ) : (
            <div className='d-flex flex-column w-100'>
              {newBlogForm !== 'quickblog' ? (
                <div className='d-flex flex-column gap-2'>
                  <span className='fw-bold fs-4 text-dark me-2 w-50 mt-4'>
                    {newBlogForm === 'wordpress' ? ` ${t('Wordpress Url')}:` : `${t('Blog Url')} :`}
                  </span>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid border border-secondary mb-3'
                    name='wordpressUrl'
                    onChange={onAddNewBlogDetail}
                  />
                </div>
              ) : (
                <div className='mt-3'>
                  <QuickBlogInputSection
                    title={t('Name')}
                    name='name'
                    onChange={onChangeQuickBlock}
                  />
                  <QuickBlogInputSection
                    title={t('Email')}
                    name='email'
                    onChange={onChangeQuickBlock}
                  />
                  <QuickBlogInputSection
                    pass
                    passDeactive={passDeactive}
                    title={t('Password')}
                    name='password'
                    onPassDeactive={() => {
                      setPassDeactive(!passDeactive)
                    }}
                    onChange={onChangeQuickBlock}
                  />
                  <QuickBlogInputSection
                    title={t('Account ID')}
                    name='account_id'
                    onChange={onChangeQuickBlock}
                  />
                </div>
              )}
              <span className='fw-bold'>
                ({t('Please make sure no pop-up blokers are active.')})
              </span>
            </div>
          )}
        </div>
        <p>{BEData?.msg}</p>
        <div className='d-flex justify-content-end'>
          {BEData?.msg ? (
            <button className='btn-sm btn-primary text-white' onClick={onPluginInstall}>
              {t('Install Application – Passwords')}
            </button>
          ) : (
            <button
              className='btn-sm btn-primary text-white'
              onClick={() => {
                if (newBlogForm === 'quickblog') {
                  onAddQuickblog(quickBlogData)
                } else {
                  onAddNewBlockCallBack()
                }
              }}
            >
              {t('Connect blog')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewBlogWrapper
