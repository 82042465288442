import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

import Swal from 'sweetalert2'

import {KTSVG} from '../../../../../_metronic/helpers'
import ProtectedCardItem from './components/ProtectedCardItem'
import {FileManagerServices} from '../../../../../services/api_services/FileManagerServices'

import '../index.scss'
import Uploader from '../../../file-manager/components/Uploader'
import {SidePanel} from '../../../file-manager/components/SidePanel'

function ProtectedFilesCard({userDetails}: any) {
  const {t} = useTranslation('translation')
  const [latestItems, setLatestItems] = useState([])
  const [showUploader, setShowUploader] = useState<boolean>(false)
  const [sidePanelData, setSidePanelData] = useState<any>({})
  const [isOpenSidePanel, setIsOpenSidePanel] = useState<any>(false)

  const userInfo: any = useSelector((state: any) => state.user.userInfo)

  const currentPlans = userDetails?.praxis?.data ? userDetails?.praxis?.data : null

  const history = useHistory()

  const EmptyDataSection = () => {
    return (
      <div
        className='fs-6 fw-bolder mb-2 w-100 text-hover-primary d-flex flex-column align-items-center text-center justify-content-center '
        role='button'
        onClick={handleUploadFiles}
      >
        <KTSVG
          path='/media/icons/duotune/files/fil022.svg'
          className='svg-icon-5x svg-icon-dark mb-2'
        />
        <ins>{t('No files Uploaded yet, click here to upload.')}</ins>
      </div>
    )
  }

  const openSideBar = (id: any) => {
    FileManagerServices.getSubmissionDetail(id).then((res) => {
      setSidePanelData(res?.data?.data)
      setIsOpenSidePanel(true)
    })
  }

  const getLatestFiles = () => {
    FileManagerServices.latestItems().then((res) => {
      if (res?.status === 200) {
        setLatestItems(res?.data?.data)
      }
    })
  }

  const handleUploadFiles = () => {
    const remains = userInfo?.data?.praxis?.data?.limits?.copyright?.can?.items?.remains

    if (remains === 0) {
      Swal.fire({
        title: !currentPlans?.tier['copyright']
          ? t("Your current plan doesn't support more file uploads")
          : t('You dont have a active plan'),
        text: t('Please consider upgrading your plan.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Go to plans'),
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: '/pages/profile/plans',
          })
        } else {
          setShowUploader(false)
        }
      })
    } else {
      setShowUploader(true)
    }
    setShowUploader(true)
  }

  useEffect(() => {
    getLatestFiles()
  }, [])

  return (
    <div className='card'>
      <div className='fs-3 card-header fw-bold w-100 align-items-center'>
        {t('Recent Protected Files')}
      </div>
      {latestItems && latestItems?.length > 0 ? (
        <div className='card-body latestItems_body'>
          {latestItems.map((item: any, idx) => {
            return <ProtectedCardItem item={item} key={idx} onOpenSideBar={openSideBar} />
          })}
        </div>
      ) : (
        <div className='card-body'>
          <EmptyDataSection />
        </div>
      )}
      {showUploader && (
        <Uploader
          setShowUploader={(e: any) => {
            setShowUploader(e)
          }}
          showUploader={showUploader}
          onUploadSuccess={() => {
            setShowUploader(false)
            history.push({
              pathname: '/file-manager',
            })
          }}
        />
      )}
      {isOpenSidePanel && (
        <SidePanel
          submissionThumb={sidePanelData?.content?.data?.file_thumb}
          submissionType={sidePanelData?.content?.data?.file_type}
          setSidePanelData={setSidePanelData}
          sidePanelData={sidePanelData}
          handleClose={() => setIsOpenSidePanel(false)}
          sidePanelToogle={isOpenSidePanel}
        ></SidePanel>
      )}
    </div>
  )
}

export default ProtectedFilesCard
