import {Modal} from 'react-bootstrap-v5'
import {VerificationService} from '../../../services/api_services/VerificationService'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useState} from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import {ToastContainer, toast} from 'react-toastify'
import {NotifySuccess, NotifyError} from '../../Helpers/Toast'
import 'react-toastify/dist/ReactToastify.css'
import {useDispatch, useSelector} from 'react-redux'
import {fetchUserInfo} from '../../../setup/reduxslice/slices/user'
import {useTranslation} from 'react-i18next'
import Cookies from 'js-cookie'

const VerificationNeeded = (props: any) => {
  const {t} = useTranslation('translation')
  const [errorMsg, setErrorMsg] = useState({isInValid: false, message: ''} as any)
  const [number, setNumber] = useState('')
  const [inputCode, setInputCode] = useState(false)
  const [OTP, setOTP] = useState('')
  const dispatch = useDispatch()
  const userInfo = useSelector((state: any) => state.user.userInfo)
  const isOpen =
    userInfo?.data?.need_email_verification ||
    userInfo?.data?.profile?.data?.need_phone_verification
  const isEmail = userInfo?.data?.need_email_verification
  const isPhone = userInfo?.data?.profile?.data?.need_phone_verification

  const getRedirectPath = () => {
    if (Cookies.get('name') === 'whale') {
      return '/auth/login?plan=whale'
    } else if (Cookies.get('name') === 'toro') {
      return '/auth/login?plan=toro'
    } else {
      return '/auth/login'
    }
  }

  const sendEmail = () => {
    VerificationService.resendEmail(userInfo?.data?.email)
      .then((res) => {
        NotifySuccess(t('an email has been sent! Check your inbox.'))
      })
      .catch((e) => {
        NotifyError('Error occured!')
      })
  }

  const requestOTP = () => {
    VerificationService.getOtp(number)
      .then((res) => {
        if (res.data.success.success === false) {
          NotifyError(t('This phone is already registered'))
        } else {
          NotifySuccess(t('verification code has been sent to your mobile!'))
          setErrorMsg({isInValid: false, message: ''})
          setInputCode(true)
        }
      })
      .catch((e) => {
        setErrorMsg({isInValid: true, message: e?.data?.message})
        NotifyError('Error occured!')
      })
  }

  const sendOTP = () => {
    VerificationService.sendOtp(OTP)
      .then((res) => {
        if (res?.data?.success) {
          NotifySuccess(t('Successfully Verified your number'))
          if (!userInfo?.data?.need_email_verification) {
            NotifySuccess(t('Thanks for verifying your account!'))
            setTimeout(() => {
              dispatch(fetchUserInfo())
            }, 4000)
          } else {
            dispatch(fetchUserInfo())
          }
        } else {
          NotifyError(t('The code you have entered is incorrect!'))
        }
      })
      .catch((e) => {
        toast('Error occured!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        })
      })
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={isOpen}
    >
      <ToastContainer />
      <div className='container-xxl px-10 py-10'>
        {isEmail && (
          <span className='d-flex flex-column flex-lg-row align-items-center justify-content-between mb-10'>
            <div className='d-flex flex-column flex-lg-row text-center text-lg-start align-items-center'>
              <span className='fs-4 fw-bolder me-4 w-100px'>{t('EMAIL')}</span>
              <span className='fs-4 me-5 w-400px'>
                {t('Click on the email we have sent to verify your account')}.
              </span>
            </div>
            <button className='btn btn-primary' onClick={() => sendEmail()}>
              {t('re-send Email Verification')}
            </button>
          </span>
        )}
        {!isEmail && isPhone && (
          <span className='d-flex flex-column flex-lg-row align-items-center justify-content-between'>
            <div className='d-flex flex-column flex-lg-row text-center text-lg-start align-items-center'>
              <span className='fs-4 fw-bolder me-4 w-100px'>{t('PHONE')}</span>
              <span className='fs-4 me-5 w-350px'>{t('Please verify your Phone number')}</span>
            </div>
            {inputCode ? (
              <div className='d-flex align-items-center flex-column flex-lg-row'>
                <ReactInputVerificationCode
                  onChange={(value) => {
                    setOTP(value)
                  }}
                  length={6}
                />
                <button
                  className='btn-small btn-primary w-100 text-white h-35px'
                  onClick={() => sendOTP()}
                  disabled={OTP.length < 6}
                >
                  {t('Verify')}
                </button>
              </div>
            ) : (
              <div className='d-flex flex-column'>
                <div className='d-flex flex-column flex-lg-row'>
                  <div>
                    <PhoneInput
                      country='ca'
                      onBlur={(e) => {
                        setNumber(e.target.value)
                      }}
                    />
                  </div>
                  <button
                    className={`btn-small btn-primary text-white  w-100`}
                    onClick={() => requestOTP()}
                  >
                    {t('Send Verify Code')}
                  </button>

                </div>
                {errorMsg?.isInValid && <div className='text-danger'>*{errorMsg?.message}</div>}
              </div>
            )}
          </span>
        )}
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 25}}>
          <button
            className='btn btn-outline btn-bg-light btn-color-gray-600'
            onClick={() => {
              localStorage.removeItem('access_token')
              window.location.href = getRedirectPath()
            }}
          >
            {t('logout')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default VerificationNeeded
