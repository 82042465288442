import React, {Suspense} from 'react'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import Routes from './routing/Routes'
import {LocaleProvider} from '../hooks/localization'
import '../i18n'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <LocaleProvider>
            <Routes />
          </LocaleProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
