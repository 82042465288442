import React from 'react'
import LiveMonitoringMap from '../../../live-monitoring/components/LiveMonitoringMap'
import useMonitoringHook from '../../../live-monitoring/hooks/useMonitoringHook'
import {useHistory} from 'react-router-dom'
import ButtonWrapper from '../ActionsCard/components/ButtonWrapper'
import {useTranslation} from 'react-i18next'

function MonitoringCard({userDetails}: any) {
  // ==============DONT REMOVE==============
  const [
    handleCountryFilter,
    handleEntryCallBack,
    getFilesType,
    loadEntriesSperately,
    loadBasedOnFilter,
    getFindingDetails,
    handleCurrentFinding,
    whiteListCallBack,
    removeWhiteListCallBack,
    getMapData,
  ] = useMonitoringHook()
  // ==============DONT REMOVE==============

  const history = useHistory()

  const {t} = useTranslation('translation')

  const currentPlans = userDetails?.praxis?.data ? userDetails?.praxis?.data : null

  return (
    <div className='card'>
      <div className='fs-3 card-header fw-bold w-100 align-items-center'>
        {t('Current Monitoring Status')}
      </div>
      {(currentPlans?.tier['defender'] ||
        userDetails?.praxis?.data?.usage?.defender?.total > 0) && (
        <div className='card-body p-0'>
          <LiveMonitoringMap
            className='bordered'
            data={getMapData()}
            onClick={(obj: any) => {
              history.push(
                `live-monitoring?countryCode=${obj?.countryCode}&countryName=${obj?.countryName}`
              )
            }}
          />
        </div>
      )}
      {!currentPlans?.tier['defender'] && (
        <div className='card-body py-3'>
          <ButtonWrapper
            id='plan_defender'
            note={t('Activate text and image monitoring')}
            icon='duotune/general/gen050.svg'
            isItInvalidPlan={true}
          />
        </div>
      )}
    </div>
  )
}
export default MonitoringCard
