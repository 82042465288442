/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {handleLoginService} from '../../../../setup/reduxslice/slices/auth'
import {fetchUserInfo} from '../../../../setup/reduxslice/slices/user'
import {NotifyError, NotifyInfo} from '../../../Helpers/Toast'
import ShowPassword from './ShowPassword'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {t} = useTranslation('translation')

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Wrong email format'))
      .min(3, t('Minimum 3 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .required(t('Email is required')),
    password: Yup.string()
      .min(3, t('Minimum 3 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .required(t('Password is required')),
  })

  const [loading, setLoading] = useState(false)
  const [passwordInputType, setPasswordInputType] = useState('password')
  const dispatch = useDispatch()
  const userInfoStatus = useSelector((state: any) => state.user.status)
  const loginStatus = useSelector((state: any) => state.auth.status)

  const history = useHistory()

  useEffect(() => {
    const token = !!localStorage.getItem('access_token')
      ? JSON.parse(localStorage.getItem('access_token') || '{}')
      : ''
    if (!!token && userInfoStatus === 'idle') {
      dispatch(fetchUserInfo())
    } else if (loginStatus === 'succeeded' && userInfoStatus === 'idle') {
      NotifyInfo(t('Login success'))
      setLoading(false)
      dispatch(fetchUserInfo())
    } else if (loginStatus === 'failed') {
      setLoading(false)
    } else if (userInfoStatus === 'succeeded') {
      history.push({
        pathname: '/dashboard/',
      })
    } else if (userInfoStatus === 'failed') {
      localStorage.removeItem('access_token')
      NotifyError(t('Unable to retrieve user information'))
      setTimeout(() => {
        window.location.reload()
      }, 1000)

      setLoading(false)
    }
  }, [userInfoStatus, dispatch, history, loginStatus])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      dispatch(handleLoginService(values))
    },
  })
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{t('Sign In to Copyrights World')}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {t('New Here?')}{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            {t('Create an Account')}
          </Link>
        </div>
      </div>
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}
      <ToastContainer />
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{t('Email')}</label>
        <input
          placeholder={t('Email')}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{t('Password')}</label>
            <Link
              to='/password-forgot'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {t('Forgot Password ?')}
            </Link>
          </div>
        </div>
        <div className='d-flex align-items-center position-relative my-1 mb-3 mb-lg-1'>
          <input
            type={passwordInputType}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <ShowPassword
            passwordInputType={passwordInputType}
            onPasswordInputType={(e: any) => setPasswordInputType(e)}
          />
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>{t('Continue')}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t('Please wait...')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
