import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {AuthenticationServices} from '../../../../services/api_services/AuthenticationServices'
import {NotifySuccess} from '../../../Helpers/Toast'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useTranslation} from 'react-i18next'
import ShowPassword from './ShowPassword'

export function ResetPassword() {
  const history = useHistory()
  const {t} = useTranslation('translation')
  const passwordFormValidationSchema = Yup.object().shape({
    email: Yup.string().email(t('Wrong email format')).required(t('Email is required')),
    newPassword: Yup.string()
      .min(8, t('Minimum 8 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('Must Contain 8 Characters, One Letter, One Number and One Special Case Character')
      ),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], t('Passwords must match')),
  })
  const data = history?.location?.search?.split('&')
  const email = data[0]?.replace('?email=', '')
  const token = data[1]?.replace('token=', '')

  const [loading2, setLoading2] = useState(false)
  const [passwordInputType, setPasswordInputType] = useState('password')

  const formik2 = useFormik<any>({
    initialValues: {
      email: email,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setLoading2(true)
      var param = {
        email: values.email,
        password: values.newPassword,
        token: token,
        password_confirmation: values.passwordConfirmation,
      }
      AuthenticationServices.resetPassword(param).then((res) => {
        NotifySuccess(t('Your password have been resetted!'))
        setTimeout(() => {
          window.location.href = '/login'
        }, 3500)
      })
      setTimeout((values) => {
        setLoading2(false)
      }, 1000)
    },
  })

  return (
    <>
      <ToastContainer />
      <form
        onSubmit={formik2.handleSubmit}
        id='kt_signinn_change_password'
        // className='form'
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{t('Reset Password')}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {t('Enter your email to reset your password.')}
          </div>
          {/* end::Link */}
        </div>
        <div className='row mb-1'>
          <div className='col-lg-12 mb-5'>
            <div className='fv-row '>
              <label htmlFor='email' className='form-label fs-6 fw-bolder mb-3'>
                {t('Email')}
              </label>
              <input
                type='email'
                disabled
                className='form-control form-control-lg form-control-solid '
                id='email'
                {...formik2.getFieldProps('email')}
              />
              {formik2.touched.email && formik2.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik2.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-lg-12 mb-5'>
            <div className='fv-row'>
              <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                {t('New Password')}
              </label>
              <div className='d-flex align-items-center position-relative my-1 mb-3 mb-lg-1'>
                <input
                  type={passwordInputType}
                  className='form-control form-control-lg form-control-solid '
                  id='newpassword'
                  {...formik2.getFieldProps('newPassword')}
                />
                <ShowPassword
                  passwordInputType={passwordInputType}
                  onPasswordInputType={(e: any) => setPasswordInputType(e)}
                />
              </div>
              {formik2.touched.newPassword && formik2.errors.newPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                </div>
              )}
            </div>
          </div>

          <div className='col-lg-12 mb-10'>
            <div className='fv-row'>
              <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                {t('Confirm New Password')}
              </label>
              <input
                type='password'
                className='form-control form-control-lg form-control-solid '
                id='confirmpassword'
                {...formik2.getFieldProps('passwordConfirmation')}
              />
              {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik2.errors.passwordConfirmation}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button id='kt_password_submit' type='submit' className='btn btn-primary me-2 px-6'>
            {!loading2 && 'Reset Password'}
            {loading2 && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {t('Please wait...')}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}
