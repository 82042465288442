import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'
import {ToastContainer} from 'react-toastify'
import {NotifySuccess} from '../../../Helpers/Toast'
import 'react-toastify/dist/ReactToastify.css'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useTranslation} from 'react-i18next'
import toroProtected from '../../../../_metronic/assets/image/toroblocksProtected.jpeg'
import './index.scss'

export default function SecurityBagdeModal({sidePanelData, show, handleClose}: any) {
  const {t} = useTranslation('translation')
  const [isCopied, setIsCopied] = useState(false)
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={() => {
        handleClose()
        setIsCopied(false)
      }}
    >
      <ToastContainer />
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header d-flex justify-content-between p-0 border-0'>
          <h3>{t('Security Badge')}</h3>
          <div
            className='btn btn-icon btn-sm btn-light-primary'
            onClick={() => {
              handleClose()
              setIsCopied(false)
            }}
          >
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
          <a href={`https://app.toroblocks.com/badge/${sidePanelData?.badge_key}`} target='_blank'>
            <img className='h-80px mb-3 badge-image' src={toroProtected} alt='copyright' />
          </a>
          <pre
            className={`fw-bolder text-center border rounded border-dark p-4 alert ${
              isCopied ? 'alert-success' : 'alert-secondary'
            }`}
            style={{
              fontSize: '16px',
              width: '100%',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
          >
            {`
              <!--TOROBLOCKS.COM VERIFICATION BADGE START-->
              <a href="https://app.toroblocks.com/badge/${sidePanelData?.badge_key}" 
              target="_blank"> 
              <img src=${`${window.location.origin}/media/copyrightsworld/toroblocksProtected.jpeg`} 
              border="0" alt="toroblocks Protection"></a>
              <!--TOROBLOCKS.COM VERIFICATION BADGE END-->`}
          </pre>
        </div>

        <div className='d-flex justify-content-end mt-5'>
          <CopyToClipboard
            text={`<!--TOROBLOCKS.COM VERIFICATION BADGE START-->
            <a href="https://app.toroblocks.com/badge/${sidePanelData?.badge_key}" 
            target="_blank"> 
            <img src=${`${window.location.origin}/media/copyrightsworld/toroblocksProtected.jpeg`} 
            border="0" alt="toroblocks Protection"></a>
            <!--TOROBLOCKS.COM VERIFICATION BADGE END-->`}
          >
            <button
              className='btn btn-primary'
              onClick={() => {
                NotifySuccess('Copied Clipboard')
                setIsCopied(true)
                setTimeout(() => {
                  setIsCopied(false)
                  handleClose()
                }, 1500)
              }}
            >
              {t('Click & Copy Security Badge Key')}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  )
}
