import React from 'react'
import monitoring from '../../../../../../../_metronic/assets/image/tb_logo_circle.png'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {wordpressIcon} from '../../../../../file-manager/components/helper'
import '../../../index.scss'
import {facebookIcon, instagramIcon} from '../helper'

function getItemImageCover(item: any) {
  if (!item?.content?.data?.file_thumb) {
    if (item?.source === 'ToroFacebook') {
      return facebookIcon()
    } else if (item?.source === 'ToroInstagram') {
      return instagramIcon()
    } else if (item?.source === 'Wordpress') {
      return wordpressIcon()
    } else {
      return <img className='rounded' src={monitoring} alt='' />
    }
  }
  return <img className='rounded' src={item?.content?.data?.file_thumb} alt='' />
}

function ProtectedCardItem({item, onOpenSideBar}: any) {
  return (
    <div
      className='d-flex flex-column justify-content-between rounded border border-primary-light border-hover-primary p-2 latestItems_body-item'
      onClick={() => {
        onOpenSideBar(item?.id)
      }}
    >
      {getItemImageCover(item)}
      <div
        className='fs-6 fw-bold mt-2 cursor-pointer latestItems_body-item-title'
        data-toggle='tooltip'
        title={item?.subtitle}
      >
        <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-3 me-1' />
        {item?.subtitle}
      </div>
    </div>
  )
}

export default ProtectedCardItem
