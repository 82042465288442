import { AxiosResponse } from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class SkeletonServices {
    public static async getCountryList(): Promise<AxiosResponse> {
        try {
            return await axiosSettings.get<null, AxiosResponse>('/v1/skeletons/countries',
                { data: [] })
        } catch (error:any) {
            throw Object(error.response);
        }

    }

}
