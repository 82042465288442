/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from 'react-i18next'

export default function VerifyHumanModal({show, handleClose, onVerify}: any) {
  const {t} = useTranslation('translation')
  const [verifyCheck, setVerifyCheck] = useState(false)
  const [verifyToken, setVerifyToken] = useState() as any
  const [loading, setLoading] = useState(false)

  const ReCaptchaComponent = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }
      const token = await executeRecaptcha()
      setVerifyToken(token)
    }, [executeRecaptcha])

    useEffect(() => {
      handleReCaptchaVerify()
    }, [handleReCaptchaVerify])

    return (
      <div>
        {!verifyCheck && (
          <p className='text-primary fw-bolder fs-3 mb-0'>{t('Please Click Checbox')}</p>
        )}{' '}
        {verifyCheck && loading && (
          <p className='text-primary fw-bolder fs-3 mb-0'>{t('Please Wait')} </p>
        )}
        {verifyCheck && !loading && (
          <p className='text-success fw-bolder fs-3 mb-0'>{t('Verified')}</p>
        )}
      </div>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [loading])

  useEffect(() => {
    if (verifyCheck) {
      setTimeout(() => {
        handleClose()
      }, 1000)
    }
  }, [verifyCheck])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-5 py-5'>
        <div className='modal-header py-2 d-flex justify-content-between border-0'>
          <h3>{t('Recapcha Verify')}</h3>
        </div>
        <div className='modal-body'>
          {' '}
          <label className='d-flex align-items-center gap-1 cursor-pointer text-hover-primary mb-2'>
            <input
              className='form-check-input me-3'
              type='checkbox'
              checked={verifyCheck}
              onChange={(e) => {
                setVerifyCheck(e?.target?.checked)
                setLoading(true)
                onVerify(verifyToken)
              }}
            />{' '}
            <p className='text-bold mb-0 fs-3 fw-bolder text-primary'>{t("I'm not a robot")}</p>
          </label>
          <div className='d-flex w-100 justify-content-end'>
            <ReCaptchaComponent />
          </div>
        </div>
      </div>
    </Modal>
  )
}
