
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ConfigModel } from "../../../../app/modules/auth/models/ConfigModel";
import { AuthenticationServices } from "../../../../services/api_services/AuthenticationServices";


interface IAuthState {
  config?: ConfigModel
  status: string
}

const initialState: IAuthState = {
    config: undefined,
    status: 'idle'
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    builder
      .addCase(fetchConfig.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.config = action.payload
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
});

export const fetchConfig: any = createAsyncThunk("/v1/config", async (queryParam: any , {getState}) => {
      const res = await AuthenticationServices.getAllConfig();
      return res.data
});


export const {  } = configSlice.actions;

export default configSlice.reducer;