import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class BlogService {
  public static async getWhaleAccoutBlogListPage(currentPage: number): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/whale/accounts?include=blog&page=${currentPage}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async pausedHideBlog(
    id: string,
    value: boolean,
    type: string
  ): Promise<AxiosResponse> {
    let urlToUse = `/v1/whale/accounts/${id}`
    var params = new URLSearchParams()
    params.append(type, value === true ? '1' : '0')
    try {
      return await axiosSettings.patch<null, AxiosResponse>(urlToUse, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async deleteBlog(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>(`/v1/whale/accounts/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async addWordpressBlog(
    url: string,
    redirect: string,
    signature: string
  ): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('url', url)
    params.append('redirect', redirect)
    if (signature !== undefined) {
      params.append('signature', signature)
    }
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/whale/accounts/authorize', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async addGoogleBlog(url: string, redirect: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('url', url)
    params.append('redirect', redirect)
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/whale/google/authorize', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async addQuickBlog(details: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('name', details?.name)
    params.append('email', details?.email)
    params.append('password', details?.password)
    params.append('account_id', details?.account_id)
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/quickblogs', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getBlogPosts(id: string, currentPage?: number): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/whale/posts/blog/${id}?page=${currentPage}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getPostDetail(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/whale/posts/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getWordPressCategories(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/whale/accounts/${id}?include=blog,blog.categories`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateSettingsWordpressBlog(
    id: string,
    changes: any
  ): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    changes.newCategories.map((category: any, idx: any) => {
      params.append(`settings[categories][${idx}]`, `${category.id}: ${category.name}`)
    })
    params.append('settings[fetch_type]', changes?.fetch_type)
    params.append('settings[handle_type]', changes?.handle_type)
    params.append('settings[defender]', changes?.defender === true ? '1' : '0')
    params.append('settings[get_pages]', changes?.get_pages === true ? '1' : '0')
    params.append('settings[strip_text]', changes?.strip_text === true ? '1' : '0')
    params.append('settings[only_future]', changes?.only_future === true ? '1' : '0')

    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/whale/wordpress/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateSettingsBloggerBlog(id: string, changes: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('settings[fetch_type]', changes?.fetch_type)
    params.append('settings[handle_type]', changes?.handle_type)
    params.append('settings[defender]', changes?.defender === true ? '1' : '0')
    params.append('settings[get_pages]', changes?.get_pages === true ? '1' : '0')
    params.append('settings[strip_text]', changes?.strip_text === true ? '1' : '0')
    params.append('settings[only_future]', changes?.only_future === true ? '1' : '0')
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/whale/blogspot/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateSettingsQuickBlog(id: string, changes: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('settings[fetch_type]', changes?.fetch_type)
    params.append('settings[handle_type]', changes?.handle_type)
    params.append('settings[defender]', changes?.defender === true ? '1' : '0')
    params.append('settings[strip_text]', changes?.strip_text === true ? '1' : '0')
    params.append('settings[only_future]', changes?.only_future === true ? '1' : '0')
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/quickblogs/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getAllWorpressCategories(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/whale/accounts/${id}?include=blog,blog.categories`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }
  public static async handleSubmission(id: string, isImportant: boolean): Promise<AxiosResponse> {
    try {
      return await axiosSettings.patch<null, AxiosResponse>(
        `/v1/submissions/${id}?is_important=${isImportant === true ? 0 : 1}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
