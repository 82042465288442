import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import OnBoardingModalContent from './onBoardingModalContent'

export default function OnBoardingVideo({}) {
  const userInfo = useSelector((state: any) => state.user.userInfo)

  const [isOpen, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
    sessionStorage.setItem('normal_close_onboarding', true)
  }

  useEffect(() => {
    const dontShowAgain = localStorage.getItem(`${userInfo?.data?.email}_dont_show_again`)
    const normalCloseOnBoarding = sessionStorage.getItem('normal_close_onboarding')
    const isVerified =
      !userInfo?.data?.need_email_verification &&
      !userInfo?.data?.profile?.data?.need_phone_verification
    if (!isVerified || dontShowAgain || normalCloseOnBoarding) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [userInfo])

  return (
    <Modal
      id='move-to-folder-modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={isOpen}
      onHide={onClose}
    >
      <OnBoardingModalContent onClose={onClose} onDontShowAgain={() => setOpen(false)} />
    </Modal>
  )
}
