import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {KTSVG} from '../../../../helpers'

export default function OnBoardingModalContent({onClose, onDontShowAgain}: any) {
  const userInfo = useSelector((state: any) => state.user.userInfo)
  const {t} = useTranslation('translation')
  return (
    <div className='container-xl px-10 py-10'>
      <div className='modal-header py-2 d-flex justify-content-between border-0'>
        <h3>{t('Toroblocks quick tour')}</h3>
        <div className='btn btn-icon btn-sm btn-light-primary' onClick={onClose}>
          <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body'>
        <div style={{margin: '0 auto', textAlign: 'center'}}>
          <iframe
            style={{width: '100%', minHeight: '400px'}}
            src='https://www.youtube.com/embed/lH4VyK7Mj3s'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
        <br></br>
        <button
          type='button'
          className='btn btn-primary'
          style={{float: 'right'}}
          onClick={() => {
            localStorage.setItem(`${userInfo?.data?.email}_dont_show_again`, 'true')
            onDontShowAgain()
          }}
        >
          {t("Don't show this again")}
        </button>
      </div>
    </div>
  )
}
