import React from 'react'
import {useTranslation} from 'react-i18next'

function InvalidPlanMessage({goToPlanPage}: any) {
  const {t} = useTranslation('translation')
  return (
    <span className='fw-bold fs-4 text-dark me-2 w-100 text-center' role='button'>
      {t('You dont have any myBlogs plan active.')}{' '}
      <span className='text-hover-primary' onClick={goToPlanPage}>
        {' '}
        <ins>{t('Activate one now.')}</ins>
      </span>
    </span>
  )
}

export default InvalidPlanMessage
