import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {Chart, registerables} from 'chart.js'
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './index.css'
import {Provider} from 'react-redux'
import store from './setup/reduxslice/store'
const {PUBLIC_URL} = process.env

Chart.register(...registerables)

ReactDOM.render(
  <Provider store={store}>
    {/* <MetronicI18nProvider> */}
    <BrowserRouter>
      <App basename={PUBLIC_URL} />
    </BrowserRouter>
    {/* </MetronicI18nProvider> */}
  </Provider>,
  document.getElementById('root')
)
