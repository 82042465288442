/* eslint-disable jsx-a11y/anchor-is-valid */
import {useTranslation} from 'react-i18next'
import 'react-tabs/style/react-tabs.css'
import 'react-toastify/dist/ReactToastify.css'
import {KTSVG} from '../../../helpers'
import './index.scss'

const SidePanel: any = ({sidePanelToogle, onClose}: any) => {
  const {t} = useTranslation('translation')

  return (
    <div
      className='position-fixed top-0 end-0 mh-100 bg-white'
      id={`${sidePanelToogle ? 'news_sidepanel_open' : 'news_sidepanel_close'}`}
    >
      <div className='card w-100 rounded-0 mh-100'>
        <div className='card-header py-0 pe-5 d-flex flex-row' id='kt_drawer_chat_messenger_header'>
          <div className='card-title' style={{float: 'left', width: '80%'}}>
            {t('News')}
          </div>
          <div className='card-toolbar'>
            <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={() => onClose()}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>
        </div>
      </div>
      <iframe
        frameBorder='0'
        allowFullScreen
        height='100%'
        width='100%'
        title='Toroblocks News'
        src='https://notes.toroblocks.com/share/7154015/99tmgvkps0g54qgp8sbp?embed&hide-header=true'
      ></iframe>
    </div>
  )
}

export {SidePanel}
