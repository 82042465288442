import React from 'react'

import {Modal} from 'react-bootstrap-v5'

import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

function SuccessModal({isOpen, onIsOpen}: any) {
  const {t} = useTranslation('translation')
  const history = useHistory()
  return (
    <Modal
      id='kt_mega_menu_modal'
      aria-hidden='true'
      tabIndex='-1'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      contentClassName='shadow-none'
      show={isOpen}
    >
      <div className='container-xxl p-0'>
        <div className='modal-header d-flex align-items-center justify-content-between border-0'>
          <div className='d-flex align-items-center text-success fw-bold fs-4'>{t('Success!')}</div>
        </div>
        <div className='modal-body fs-5 font-bolder'>
          {t(
            'Success! We now encrypt and process your submission. It will appear in your file manager momentarily.'
          )}
        </div>
        <div className='modal-footer d-flex w-100 justify-content-end gap-3'>
          <div className='btn btn-secondary btn-sm' onClick={() => onIsOpen(false)}>
            {t('Close')}
          </div>
          <div
            className='btn btn-primary btn-sm'
            onClick={() => history.push({pathname: '/file-manager'})}
          >
            {t('Take me to my files')}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
