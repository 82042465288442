import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../../_metronic/helpers'

function AddNewSocialInfoModal({show, accountType, onCreateAccount, handleClose}: any) {
  const {t} = useTranslation('translation')
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-5'>
        <div className='modal-header d-flex justify-content-between p-0 pb-5 border-0'>
          <h3>{t('Add New Social Account')}</h3>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div id='account_post-modal' className='h-25'>
          <span className='fw-bold fs-4 text-dark me-2 w-100 text-center'>
            {t(
              `You will be now get redirected to ${accountType} to continue with the account connection`
            )}
            .{' '}
          </span>
        </div>
        <div className='modal-footer p-0 pt-2'>
          <button className='btn btn-sm btn-secondary' onClick={handleClose}>
            {t('Close')}
          </button>
          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              onCreateAccount(accountType)
              handleClose()
            }}
          >
            {t('Proceed')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AddNewSocialInfoModal
