import {AxiosResponse} from 'axios'
import {NotifyError} from '../../app/Helpers/Toast'
import axiosSettings from '../api-config/axiosSettings'

export class AuthenticationServices {
  public static token = `Bearer ${
    !!localStorage.getItem('access_token')
      ? JSON.parse(localStorage.getItem('access_token') || '{}')
      : ''
  }`
  public static async login(email: string, password: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/clients/web/login', params)
    } catch (error) {
      error &&
        NotifyError(
          error?.response?.data?.errors?.email[0]
            ? error?.response?.data?.errors?.email[0]
            : error?.response?.message
        )
      throw Object(error)
    }
  }

  public static async forgotPassword(email: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('email', email)
    params.append('reseturl', `${window.location.origin}/password-reset`)
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/password/forgot', params)
    } catch (error) {
      throw Object(error)
    }
  }

  public static async resetPassword(data: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('email', data.email)
    params.append('password', data.password)
    params.append('password_confirmation', data.password_confirmation)
    params.append('token', data.token)
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/password/reset', params)
    } catch (error) {
      throw Object(error)
    }
  }
  public static async resetPasswordFromAccount(data: any, userId: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('password', data.password)
    params.append('password_confirmation', data.password_confirmation)
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/users/${userId}`, params)
    } catch (error) {
      throw Object(error)
    }
  }

  public static async register(
    email: string,
    firstname: string,
    lastname: string,
    country_id: string,
    password: string,
    accept_tos: any,
    localization: string
  ): Promise<AxiosResponse> {
    const affiliateId = localStorage.getItem('affiliate_id')
    var params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    params.append('password_confirmation', password)
    params.append('name', firstname)
    params.append('last_name', lastname)
    params.append('country_id', country_id)
    params.append('accept_tos', '1')
    params.append('is_company', '0')
    params.append('localization', localization)

    if (affiliateId) {
      params.append('affiliate_id', affiliateId)
    }

    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/register', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getAllConfig(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>('/v1/config', {
        data: [],
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getAllUserInfo(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        '/v1/user/profile?include=options,profile,praxis,financial,coupons ',
        {
          data: [],
          headers: {
            Authorization: `Bearer ${
              !!localStorage.getItem('access_token')
                ? JSON.parse(localStorage.getItem('access_token') || '{}')
                : ''
            }`,
          },
        }
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateUser(userId: string, deleteUser: boolean): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('options[request_delete_at]', deleteUser ? '1' : '0')
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/users/${userId}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getAllInvoices(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>('/v1/invoices', {
        data: [],
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getAllSubscription(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>('/v1/me/subscriptions?include=items', {
        data: [],
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async uploadUserAvatar(images: any[]): Promise<AxiosResponse> {
    if (images.length === 0) {
      try {
        return await axiosSettings.delete<null, AxiosResponse>('/v1/me/avatar')
      } catch (error: any) {
        throw Object(error.response)
      }
    } else {
      var data = new FormData()
      data.append('avatar', images[0], images[0]?.name)
      try {
        return await axiosSettings.post<null, AxiosResponse>('/v1/me/avatar', data)
      } catch (error: any) {
        throw Object(error.response)
      }
    }
  }

  public static async deleteUserAvatar(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>('/v1/me/avatar')
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateUserInfo(userId: any, params: any): Promise<AxiosResponse> {
    var paramsReq = new URLSearchParams()
    if (params?.profile?.country_id)
      paramsReq.append('profile[country_id]', params.profile.country_id)
    if (params?.profile?.gender) paramsReq.append('profile[gender]', params.profile.gender)
    if (params?.profile?.birth_year)
      paramsReq.append('profile[birth_year]', params.profile.birth_year)
    if (params?.profile?.address) paramsReq.append('profile[address]', params.profile.address)
    if (params?.profile?.phone) paramsReq.append('profile[phone]', params.profile.phone)
    if (params?.name) paramsReq.append('name', params.name)
    if (params?.options?.localization)
      paramsReq.append('options[localization]', params.options.localization)
    if (params?.last_name) {
      paramsReq.append('last_name', params.last_name)
    }
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/users/${userId}`, paramsReq, {
        data: [],
        headers: {
          Authorization: `Bearer ${
            !!localStorage.getItem('access_token')
              ? JSON.parse(localStorage.getItem('access_token') || '{}')
              : ''
          }`,
        },
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
