import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class AffiliatesService {
  public static async getAll(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/me/affiliate?include=coupons,active_coupons`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async activate(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.post<null, AxiosResponse>(`/v1/affiliates`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async createCoupon(offer: string): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('offer', offer)
      return await axiosSettings.post<null, AxiosResponse>(`/v1/me/affiliate/coupons`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async deleteCoupon(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>(`/v1/me/affiliate/coupons/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async makeCouponActive(affiliateId: string, id: string): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('affiliate_coupon_id', id)
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/affiliates/${affiliateId}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getSignups(currentPage: number): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/me/affiliate/customers?page=${currentPage}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getIncomeDetails(currentPage: number): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/me/affiliate/income?page=${currentPage}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async trackLink(uuid: string, user?: string): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('uuid', uuid)
      if (user) {
        params.append('user', user)
      }
      return await axiosSettings.post<null, AxiosResponse>(`/v1/affiliates/track`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
