import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import useLocale from '../../../../hooks/useLocale'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {AuthenticationServices} from '../../../../services/api_services/AuthenticationServices'
import {KTSVG} from '../../../helpers'
import './index.scss'

export default function LanguageSwitcher({}) {
  const [isOpen, setOpen] = useState(false)
  const local = useLocale()
  const ref = useRef() as any
  useOnClickOutside(ref, () => setOpen(false))

  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const currentUserData = userInfo?.data ? userInfo?.data : {}

  const handleLang = (langType: string) => {
    local.setLocale(langType)
    const updatedLocalizationData = {options: {localization: langType}}
    AuthenticationServices.updateUserInfo(currentUserData?.id, updatedLocalizationData)
  }

  useEffect(() => {
    if (
      currentUserData &&
      currentUserData?.options &&
      currentUserData?.options?.data &&
      currentUserData?.options?.data?.localization &&
      !localStorage.getItem('lang')
    ) {
      local.setLocale(currentUserData?.options?.data?.localization)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginRight: '10px',
        position: 'relative',
      }}
    >
      <div onClick={() => setOpen(!isOpen)}>
        <KTSVG path='/media/icons/duotune/custom/language.svg' className='language_icon' />
      </div>

      {isOpen && (
        <div
          ref={ref}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            padding: '10px 20px',
            bottom: '-67px',
            right: '0px',
          }}
        >
          <div onClick={() => handleLang('en')}>English</div>
          <div style={{marginTop: '10px'}} onClick={() => handleLang('es')}>
            Española
          </div>
          <div style={{marginTop: '10px'}} onClick={() => handleLang('el')}>
            Ελληνικά
          </div>
        </div>
      )}
    </div>
  )
}
