import React from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

export default function ProfileCardHeader({userDetails}: any) {
  return (
    <>
      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
        <img src={toAbsoluteUrl(userDetails?.profile?.data?.profile_photo_path)} alt='Metornic' />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <span className='text-gray-800 fs-2 fw-bolder'>
          {`${userDetails?.name} ${userDetails?.last_name}`}
        </span>
        <span className='text-gray-800 fs-6'>{userDetails?.profile?.data?.country}</span>
      </div>
    </>
  )
}
