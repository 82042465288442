import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'

export default function FileManagerImageModal({show, data, sidePanelData, handleClose}: any) {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={() => {
        handleClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header d-flex justify-content-between p-0 border-0 mb-3'>
          <h3>{sidePanelData?.post?.data?.permanent_url ? 'Post' : 'File'}</h3>
          <div
            className='btn btn-icon btn-sm btn-light-primary'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        {data ? (
          <img src={data} width='100%' height='100%' />
        ) : (
          <div className='mt-4'>
            {sidePanelData?.post?.data?.permanent_url ? (
              <a href={sidePanelData?.post?.data?.permanent_url} target='_blank'>
                <img src={sidePanelData?.post?.data?.preview_image} width='100%' height='100%' />
              </a>
            ) : (
              <img src={sidePanelData?.content?.data?.file_thumb} width='100%' height='100%' />
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
