/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ResetPassword} from './components/ResetPassword'
import {AffiliatesCoupon} from '../../pages/affiliates/AffiliatesCoupon'
import toroBlocksLogo from '../../../_metronic/assets/image/tb_full.png'
import cwPoweredBy from '../../../_metronic/assets/image/cw_powered_by.png'
import BadgeWrapper from '../../pages/badge/BadgeWrapper'
import Cookies from 'js-cookie'

export function AuthPage() {
  const [redirectPlan, setRedirectPlan] = useState() as any

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    const queryParams = window.location.href
    const html: any = document.querySelector('html')
    if (queryParams.search('plan') !== -1) {
      const currentParameter = queryParams.split('=')[1]
      Cookies.set('name', currentParameter, {expires: 1})
      setRedirectPlan(currentParameter)
      if (currentParameter === 'whale') {
        html.dataset.theme = 'theme-whale'
        document.body.style.backgroundImage = 'url("/media/patterns/header-bg-old.jpg")'
      } else if (currentParameter === 'toro') {
        html.dataset.theme = 'theme-toro'
        document.body.style.backgroundImage = 'url("/media/patterns/header-bg-dark.png")'
      }
    } else {
      Cookies.remove('name')
      html.dataset.theme = ''
      // document.body.style.backgroundImage = 'url("/media/patterns/header-bg.jpg")'
      setRedirectPlan('')
    }
  }, [])

  const getPageTitleLogo = (): any => {
    if (Cookies.get('name') === 'whale') {
      return {
        titleLogo: (
          <>
            {' '}
            <img alt='toro' src={toroBlocksLogo} className='h-55px d-lg-none' />
            <img alt='toro' src={toroBlocksLogo} className='h-70px d-none d-lg-block' />
          </>
        ),
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        footerLogo: (
          <div className='d-flex flex-column flex-lg-row w-100 justify-content-center align-items-center'>
            <span className='w-100 d-flex justify-content-center'>
              <img className='h-100px' src={cwPoweredBy} alt='copyright' />
            </span>
          </div>
        ),
      }
    } else if (redirectPlan === 'toro') {
      return {
        titleLogo: (
          <>
            {' '}
            <img alt='toro' src={toroBlocksLogo} className='h-55px d-lg-none' />
            <img alt='toro' src={toroBlocksLogo} className='h-70px d-none d-lg-block' />
          </>
        ),
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        footerLogo: (
          <div className='d-flex flex-column flex-lg-row w-100 justify-content-center align-items-center'>
            <span className='w-100 d-flex justify-content-center'>
              <img className='h-100px' src={cwPoweredBy} alt='copyright' />
            </span>
          </div>
        ),
      }
    } else {
      return {
        titleLogo: (
          <>
            {' '}
            <img alt='toro' src={toroBlocksLogo} className='h-55px d-lg-none' />
            <img alt='toro' src={toroBlocksLogo} className='h-70px d-none d-lg-block' />
          </>
        ),
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        footerLogo: (
          <div className='d-flex flex-column flex-lg-row w-100 justify-content-center align-items-center'>
            <span className='w-100 d-flex justify-content-center'>
              <img className='h-100px' src={cwPoweredBy} alt='copyright' />
            </span>
          </div>
        ),
      }
    }
  }

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: getPageTitleLogo()?.backgroundImage,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mt-4 mt-lg-0 mb-12'>
          {getPageTitleLogo()?.titleLogo}
        </a>
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Redirect from='/login' exact={true} to='/auth/login' />
            <Redirect from='/register' exact={true} to='/auth/registration' />
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/password-forgot' component={ForgotPassword} />
            <Route path='/password-reset' component={ResetPassword} />
            <Route path='/affiliates-coupon/:id' component={AffiliatesCoupon} />
            <Route path='/badge/:id' component={BadgeWrapper} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        <div
          className='w-lg-600px p-10 p-lg-15 mx-auto'
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          {getPageTitleLogo()?.footerLogo}
        </div>
      </div>
    </div>
  )
}
