import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class VerificationService {
  public static async resendEmail(email: string): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('email', email)
      return await axiosSettings.post<null, AxiosResponse>(`/v1/resend/verify`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getOtp(phone: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/send/otp/${phone}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async sendOtp(code: string): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('code', code)
      return await axiosSettings.post<null, AxiosResponse>('/v1/verify/otp', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
