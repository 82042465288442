import React, {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {BadgeService} from '../../../services/api_services/BadgeService'
import ImageModal from './components/ImageModal'
import './components/index.scss'

const BadgeWrapper: FC = () => {
  const {t} = useTranslation('translation')
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const [badgeDetails, setBadgeDetails] = useState({} as any)
  const [viewModal, setViewModal] = useState(false)

  const {id}: any = useParams()

  const getBadgeDetails = async () => {
    try {
      BadgeService.badgeDetail(id).then((res) => {
        const currentDetails = res?.data?.data
        if (currentDetails) {
          setBadgeDetails(currentDetails)
        }
      })
    } catch (error) {}
  }

  useEffect(() => {
    getBadgeDetails()
  }, [id])

  return (
    <div className='100% d-flex justify-content-center'>
      <div
        className='card w-100'
        style={userInfo ? {maxWidth: '600px', padding: '4rem'} : {padding: '1rem'}}
      >
        <div className='card-body  w-100' style={!userInfo ? {padding: '1rem'} : {padding: '1rem'}}>
          <div className='w-100 d-flex align-items-center flex-column justify-content-between border-bottom mb-4 pb-4'>
            <div className='badge_image-wrapper border rounded hover-border-dark mb-2'>
              <img
                className='mb-3'
                src={badgeDetails?.preview}
                style={{width: '500px', height: '300px', objectFit: 'cover'}}
                alt=''
              />
            </div>
            <button className='btn btn-primary mb-6' onClick={() => setViewModal(true)}>
              {t('Full View')}
            </button>
            <h3
              style={{
                maxWidth: '325px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {badgeDetails?.title}
            </h3>
          </div>
          <div className='d-flex flex-column gap-3'>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Owner')}:</h3>
              <h5 className='fw-normal'>{badgeDetails?.owner}</h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Digital Signature')}:</h3>
              <h5 className='text-end fw-normal' style={{maxWidth: '200px'}}>
                {badgeDetails?.digital_signature}
              </h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Asset Name')}:</h3>
              <h5 className='fw-normal' style={{maxWidth: '200px'}}>
                {badgeDetails?.asset_name}
              </h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Submission Year')}:</h3>
              <h5 className='fw-normal'>{badgeDetails?.submission_year}</h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Submission Type')}:</h3>
              <h5 className='fw-normal'>{badgeDetails?.submission_type}</h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Submission Size')}:</h3>
              <h5 className='fw-normal'>{badgeDetails?.submission_size}</h5>
            </span>
            <span className='w-100 d-flex align-items-center justify-content-between'>
              <h3>{t('Create at')}:</h3>
              <h5 className='fw-normal'>{badgeDetails?.readable_created_at}</h5>
            </span>
          </div>
        </div>
      </div>
      <ImageModal
        data={badgeDetails?.preview}
        type={badgeDetails?.submission_type}
        show={viewModal}
        handleClose={() => setViewModal(false)}
      />
    </div>
  )
}

export default BadgeWrapper
