import {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {DefenderServices} from '../../../../services/api_services/DefenderServices'
import {NotifyError, NotifyInfo, NotifySuccess} from '../../../Helpers/Toast'
import {fetchConfig} from '../../../../setup/reduxslice/slices/config'
import TableTag from '../components/TableTag'
import {
  getObjFromUrlQueryParams,
  getUpdatedUrl,
  isFilteringByNewCountry,
  isFilteringByNewEntryId,
  isFilteringByNewSubmissionType,
  removeSearchInputValue,
} from '../helper'
import debouce from 'lodash.debounce'
import Swal from 'sweetalert2'
import {useHistory} from 'react-router'
import {
  setActiveTab,
  setCompareModalOpen,
  setCountPerPage,
  setCountPerPageForTextFiles,
  setCurrentFinding,
  setCurrentIndex,
  setCurrentPage,
  setExternalHandleSubmission,
  setFiles,
  setFilters,
  setForceChangePage,
  setLoading,
  setMapData,
  setSelectedItems,
  setShowUploader,
  setTextFiles,
  setTotalData,
  setTotalTextData,
} from '../../../../setup/reduxslice/slices/monitoring'

const useMonitoringHook = () => {
  const {t} = useTranslation('translation')
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const {
    filters,
    files,
    mapData,
    selectedItems,
    currentFinding,
    forceChangePage,
    totalData,
    totalDataForTexts,
    currentIndex,
    countPerPage,
    currentPage,
  }: any = useSelector((state: any) => state.monitoring)
  const configStatus = useSelector((state: any) => state.config.status)

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    if (configStatus === 'idle') {
      dispatch(fetchConfig())
    }
  })

  function handleCountryFilter(obj: any) {
    let currentCountriesFilter = [...filters?.countries]
    const currentCountry = currentCountriesFilter.find(
      (x: {countryValue: any}) => x.countryValue === obj.countryValue
    )
    if (currentCountry) currentCountriesFilter = []
    else currentCountriesFilter = [{...obj}]
    dispatch(setFilters({...filters, countries: currentCountriesFilter, submissionType: null}))
    window.history.pushState(
      {},
      '',
      getUpdatedUrl(
        {...filters, countries: currentCountriesFilter, submissionType: null},
        currentPage
      )
    )
  }

  const handleEntryCallBack = (entry: any) => {
    dispatch(setFilters({...filters, entry: entry, submissionType: null}))
    window.history.pushState({}, '', getUpdatedUrl({...filters, submissionType: null}, currentPage))
  }

  const getFilesType = () => {
    const entryId = filters?.entry?.id
    if (filters?.submissionType) {
      return filters?.submissionType
    } else {
      if (!entryId && filters?.countries?.length === 0) return 'ALL'
      else if (entryId && filters?.countries?.length === 0) return 'FINDINGS'
      else if (entryId && filters?.countries?.length > 0) return 'FINDINGS_COUNTRIES'
      else if (!entryId && filters?.countries?.length > 0) return 'COUNTRIES'
    }
  }

  const loadFindingsForEntries = (pageNumber?: number) => {
    dispatch(setLoading(true))
    const countries = filters?.countries?.map((x: any) => x?.countryCode)
    try {
      DefenderServices.getDefenderDetail(
        filters?.entry?.id,
        filters?.entry?.type,
        countries,
        filters?.extraFilter,
        pageNumber
      ).then((response: any) => {
        const currentFiles = response?.data?.data
        dispatch(setFiles(currentFiles))
        dispatch(setTotalData(response?.data?.meta?.pagination?.total))
        dispatch(setCountPerPage(response?.data?.meta?.pagination?.per_page))
        dispatch(setCurrentPage(response?.data?.meta?.pagination?.current_page))
        dispatch(setLoading(false))
        if (filters?.entry?.type === 'text') {
          dispatch(setTotalData(0))
          dispatch(setTotalTextData(response?.data?.meta?.pagination?.total))
        }
      })
    } catch (err) {
      NotifyError('Error')
      dispatch(setLoading(false))
    }
  }

  const loadFiles = (pageNumber?: number) => {
    DefenderServices.getDefenders(
      pageNumber,
      filters?.extraFilter?.showOnlyMatches,
      filters?.extraFilter?.latestItems,
      filters?.searchParam,
      filters?.submissionType
    ).then((response: any) => {
      const files = response?.data?.data
      dispatch(setFiles(files))
      dispatch(setTotalData(response?.data?.meta?.pagination?.total))
      dispatch(setCountPerPage(response?.data?.meta?.pagination?.per_page))
      dispatch(setCurrentPage(response?.data?.meta?.pagination?.current_page))
    })
  }

  const setMapDataInternally = (res: any) => {
    const data = res?.data?.data
    if (data) {
      const allKeys = Object.keys(data)
      let result: any[] = []
      allKeys.forEach((x) => result?.push({country: x.toLowerCase(), value: data[x]}))
      dispatch(setMapData(result))
    }
  }

  const loadGeneralStatistics = () => {
    dispatch(setLoading(true))
    DefenderServices.getDefenderStatistics().then((res) => {
      setMapDataInternally(res)
      dispatch(setLoading(false))
    })
  }

  const loadTexts = (pageNumber?: number) => {
    dispatch(setLoading(true))
    const countries = filters?.countries?.map((x: any) => x.countryCode)
    try {
      DefenderServices.getDefenderFilesByType(
        'text',
        countries,
        filters?.extraFilter,
        pageNumber
      ).then((response: any) => {
        const files = response.data.data
        if (filters?.submissionType) {
          dispatch(setFiles(files))
          dispatch(setTotalData(response.data.meta.pagination.total))
          dispatch(setCountPerPage(response.data.meta.pagination.per_page))
          dispatch(setCurrentPage(pageNumber ? pageNumber : 1))
        } else {
          dispatch(setTextFiles(files))
          dispatch(setTotalTextData(response?.data?.meta?.pagination?.total))
          dispatch(setCountPerPageForTextFiles(response?.data.meta?.pagination?.per_page))
          dispatch(setCurrentPage(pageNumber ? pageNumber : 1))
        }
        dispatch(setLoading(false))
      })
    } catch (err) {
      NotifyError('Error')
      dispatch(setLoading(false))
    }
  }

  const loadImages = (pageNumber?: number) => {
    dispatch(setLoading(true))
    const countries = filters?.countries?.map((x: any) => x.countryCode)
    try {
      DefenderServices.getDefenderFilesByType(
        'image',
        countries,
        filters?.extraFilter,
        pageNumber
      ).then((response: any) => {
        const files = response.data.data
        dispatch(setFiles(files))
        dispatch(setTotalData(response.data.meta.pagination.total))
        dispatch(setCountPerPage(response.data.meta.pagination.per_page))
        dispatch(setLoading(false))
      })
    } catch (err) {
      NotifyError('Error')
      dispatch(setLoading(false))
    }
  }

  const loadEntriesSperately = (pageNumber?: number) => {
    loadTexts(pageNumber)
    loadImages(pageNumber)
  }

  const getEntryStatistics = (entryId: string) => {
    DefenderServices.getEntryStatistics(entryId).then((res) => {
      setMapDataInternally(res)
    })
  }

  const removeSearchFilter = () => {
    if (filters?.searchParam?.length > 0) {
      dispatch(setFilters({...filters, searchParam: null}))
      window.history.pushState({}, '', getUpdatedUrl({...filters, searchParam: null}, currentPage))
    }
  }

  const loadBasedOnFilter = (pageNumber?: number) => {
    dispatch(setTotalTextData(0))
    const loadingType = getFilesType()
    if (loadingType === 'ALL') {
      loadFiles(pageNumber)
      loadGeneralStatistics()
    } else if (loadingType === 'FINDINGS') {
      removeSearchFilter()
      removeSearchInputValue()
      loadFindingsForEntries(pageNumber)
      getEntryStatistics(filters?.entry?.id)
    } else if (loadingType === 'FINDINGS_COUNTRIES') {
      removeSearchFilter()
      loadFindingsForEntries(pageNumber)
    } else if (loadingType === 'COUNTRIES') {
      removeSearchFilter()
      loadEntriesSperately(pageNumber)
    } else if (loadingType === 'IMAGE' || loadingType === 'TEXT') {
      loadFiles(pageNumber)
    }
  }

  const checkAndUpdateUrlBasedOnFilters = (currentQueryParams: any) => {
    const updatedUrl = getUpdatedUrl(filters, getFilesType())
    if (
      (filters?.entry ||
        filters?.countries?.length > 0 ||
        Object.keys(filters?.extraFilter).length > 0 ||
        filters?.submissionType !== currentQueryParams?.submission) &&
      updatedUrl !== window.location.href
    ) {
      window.history.pushState({}, '', updatedUrl)
    }
  }

  const updateFiltersBasedOnNewEntryId = (currentQueryParams: any) => {
    DefenderServices.GetEntryById(currentQueryParams?.entry?.id).then((res) => {
      if (res) {
        if (currentQueryParams?.country?.length > 0) {
          dispatch(
            setFilters({
              ...filters,
              entry: res?.data?.data,
              countries: currentQueryParams?.country,
            })
          )
        } else {
          dispatch(setFilters({...filters, entry: res?.data?.data}))
        }
      }
    })
  }

  // *********DONT REMOVE************ when close CompareModal set empty image entries
  // const updateFilterBySearchParam = (currentQueryParams: any) => {
  //   if (currentQueryParams?.searchParam && !filters?.searchParam) {
  //     setFilters({
  //       ...filters,
  //       searchParam: currentQueryParams?.searchParam,
  //     })
  //   }
  // }
  // *********DONT REMOVE************

  const updateFiltersBasedOnNewSubmissionType = (currentQueryParams: any) => {
    if (currentQueryParams?.country?.length > 0) {
      dispatch(
        setFilters({
          ...filters,
          submissionType: currentQueryParams?.submission,
          countries: currentQueryParams?.country,
        })
      )
    } else {
      dispatch(setFilters({...filters, submissionType: currentQueryParams?.submission}))
    }
  }

  const updateFiltersBasedOnNewCountry = (currentQueryParams: any) => {
    dispatch(
      setFilters({
        ...filters,
        entry: null,
        countries: currentQueryParams?.country,
      })
    )
  }

  const handleUpdatingFilters = (currentQueryParams: any) => {
    if (isFilteringByNewEntryId(currentQueryParams, filters)) {
      updateFiltersBasedOnNewEntryId(currentQueryParams)
    }
    if (isFilteringByNewSubmissionType(currentQueryParams, filters)) {
      updateFiltersBasedOnNewSubmissionType(currentQueryParams)
    }
    if (isFilteringByNewCountry(currentQueryParams, filters)) {
      updateFiltersBasedOnNewCountry(currentQueryParams)
    }
  }

  useEffect(() => {
    dispatch(setFiles([]))
    const currentQueryParams = getObjFromUrlQueryParams()
    checkAndUpdateUrlBasedOnFilters(currentQueryParams)
    handleUpdatingFilters(currentQueryParams)
    loadBasedOnFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const getFindingDetails = async (id: string) => {
    const entryId =
      getFilesType() === 'COUNTRIES' ? currentFinding?.defender_entry_id : filters?.entry?.id
    // const res = await DefenderServices.getDefenderFindingDetail(id, entryId, 'image')
    // ==========************************
    const res = await DefenderServices.getDefenderFindingDetail(id, entryId, filters?.entry?.type)
    if (res.data && res.data.data) {
      const item = res?.data?.data[0]
      dispatch(setCurrentFinding(item))
      dispatch(setCompareModalOpen(true))
    }
  }

  const handleIndex = (ind: number) => {
    if (ind >= countPerPage) {
      const rs = countPerPage * (currentPage - 1)
      return ind - rs
    }
    return ind
  }

  useEffect(() => {
    if (files?.length > 0 && forceChangePage) {
      const ind = handleIndex(currentIndex)
      const curr = files[ind] as any
      const currentFilter = getFilesType()
      dispatch(setForceChangePage(false))
      if (currentFilter === 'COUNTRIES') {
        dispatch(setCurrentFinding(curr))
        dispatch(setCompareModalOpen(true))
        return
      }
      getFindingDetails(curr?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, forceChangePage])

  const handleCurrentFinding = (changes: number, setNewIndex = false, item?: any) => {
    if (getFilesType() === 'COUNTRIES' || getFilesType() === 'IMAGE' || getFilesType() === 'TEXT') {
      handleEntryCallBack(item?.entry?.data)
    } else {
      dispatch(setForceChangePage(false))
      const newIndex = setNewIndex ? changes : currentIndex + changes
      if (newIndex % countPerPage === 0 && newIndex <= totalData && newIndex > 0) {
        if (totalData / newIndex > 1) {
          const newPage = currentPage + 1
          dispatch(setCurrentIndex(newIndex))
          dispatch(setCurrentPage(newPage))
          dispatch(setFiles([]))
          dispatch(setForceChangePage(true))
          loadBasedOnFilter(newPage)
          return
        }
      }
      const currentFilter = getFilesType()
      dispatch(setCurrentIndex(newIndex))
      const idx = handleIndex(newIndex)
      const curr = files[idx] as any
      if (currentFilter === 'COUNTRIES' && curr) {
        dispatch(setCurrentFinding(curr))
        dispatch(setCompareModalOpen(true))
        return
      }
      getFindingDetails(curr?.id)
    }
  }

  const whiteListCallBack = (currentPage: number, item: any) => {
    const domains = filters?.entry?.whitelist?.map((domain: any) => domain.id)
    dispatch(setLoading(true))
    DefenderServices.whitelistEntryAdd(filters?.entry?.id, [
      ...domains,
      item.defender_domain_id,
    ]).then((res) => {
      NotifySuccess(t('Successfully added to whitelist'))
      dispatch(setFilters({...filters, entry: res?.data?.data}))
      loadBasedOnFilter(currentPage)
      dispatch(setLoading(false))
    })
  }

  const removeWhiteListCallBack = (newDomainList: any[], currentPage: number, item: any) => {
    dispatch(setLoading(true))
    let domains = newDomainList?.map((domain: any) => domain.id)
    DefenderServices.whitelistEntryRemove(item?.id, domains).then((res) => {
      NotifySuccess(t('Successfully removed from whitelist'))
      dispatch(setFilters({...filters, entry: res?.data?.data}))
      loadBasedOnFilter(currentPage)
      dispatch(setLoading(false))
    })
  }

  const getMapData = (): any => {
    const currentFilter = getFilesType()
    if (currentFilter === 'COUNTRIES' || currentFilter === 'FINDINGS_COUNTRIES') {
      const countryCode = filters?.countries[0]?.countryCode
      return [{country: countryCode, value: totalDataForTexts + totalData}]
    } else {
      return mapData
    }
  }

  const updateFindingListForImages = async () => {
    const countries = filters?.countries?.map((x: any) => x.countryCode)
    const entryId =
      getFilesType() === 'COUNTRIES' ? currentFinding?.defender_entry_id : filters?.entry?.id
    const res = await DefenderServices.getDefenderDetail(entryId, 'image', countries, currentPage)
    if (res.data && res.data.data) {
      const data = res.data.data
      dispatch(setFiles(data))
    }
  }

  const getTotalStatistics = () => {
    const currentFilter = getFilesType()
    let total = 0
    let textFindings = 0
    let imageFindings = 0
    if (filters.submissionType) {
      total = totalData
      if (filters.submissionType === 'IMAGE') {
        imageFindings = totalData
      } else {
        textFindings = totalData
      }
    } else {
      if (currentFilter === 'ALL') {
        total = totalData
        textFindings = userInfo?.data?.praxis?.data?.usage?.defender?.findings?.text
        imageFindings = userInfo?.data?.praxis?.data?.usage?.defender?.findings?.image
        if (filters.searchParam) {
          total = totalData
          textFindings = 0
          imageFindings = 0
        }
      } else if (currentFilter === 'COUNTRIES') {
        total = totalData + totalDataForTexts
        textFindings = totalDataForTexts
        imageFindings = totalData
      } else if (currentFilter === 'FINDINGS') {
        total = totalData
        textFindings = filters?.entry?.type === 'text' ? total : 0
        imageFindings = filters?.entry?.type === 'image' ? total : 0
      } else if (currentFilter === 'FINDINGS_COUNTRIES') {
        total = totalData + totalDataForTexts
        textFindings = totalDataForTexts
        imageFindings = totalData
      }
    }
    return {
      total,
      textFindings,
      imageFindings,
    }
  }

  const handleExtraFilter = (item: any) => {
    let currentExtraFilter = filters?.extraFilter
    if (item === 'whitelisted') {
      currentExtraFilter = {...currentExtraFilter, whitelisted: ''}
      dispatch(setFilters({...filters, extraFilter: currentExtraFilter}))
    } else if (item === 'hidden') {
      currentExtraFilter = {...currentExtraFilter, hidden: ''}
      dispatch(setFilters({...filters, extraFilter: currentExtraFilter}))
    } else if (item === 'irrelevant') {
      currentExtraFilter = {...currentExtraFilter, irrelevant: ''}
      dispatch(setFilters({...filters, extraFilter: currentExtraFilter}))
    } else if (item === 'approved') {
      currentExtraFilter = {...currentExtraFilter, approved: ''}
      dispatch(setFilters({...filters, extraFilter: currentExtraFilter}))
    }
  }

  const getExtraFilterTags = () => {
    // eslint-disable-next-line array-callback-return
    return Object.entries(filters?.extraFilter)?.map((item: any, idx: any) => {
      if (item[0] !== 'showOnlyMatches' && item[1].length > 0) {
        let checkStatus = ''
        if (item[1].search('Without') !== -1) {
          checkStatus = 'without'
        }
        if (item[1].search('Only') !== -1) {
          checkStatus = 'only'
        }
        return (
          <TableTag
            key={idx}
            large
            status={item[0]}
            tag={`${checkStatus === 'without' ? 'Exclude' : 'Show Only'} ${item[0]}`}
            onClick={() => handleExtraFilter(item[0])}
          />
        )
      }
    })
  }

  const onChangeCallBack = (e: any, item: any, items?: any) => {
    let currentSelectedItems: any = [...selectedItems]
    if (item) {
      if (e?.target?.checked) {
        currentSelectedItems.push(item)
        dispatch(setSelectedItems(currentSelectedItems))
      } else {
        const items = currentSelectedItems?.filter((x: any) => x.id !== item.id)
        dispatch(setSelectedItems(items))
      }
    } else if (items?.length > 0) {
      if (e?.target?.checked) {
        dispatch(setSelectedItems(items))
      } else {
        dispatch(setSelectedItems([]))
      }
    }
  }

  const handleRemoveFromMonitoring = (items: any) => {
    DefenderServices.RemoveEntry(items).then((res) => {
      if (res) {
        NotifySuccess(`${t('Files have been removed')}`)
      }
    })
  }

  const handlePauseEntry = (item: any) => {
    DefenderServices.pausedEntry(item.id, !item.paused).then((res) => {
      if (res?.data?.data?.paused) {
        NotifyInfo(
          `${item?.title.slice(0, 10)} ${t(
            'This Entry have been paused from monitoring successfully'
          )}`
        )
      } else {
        NotifySuccess(
          `${item?.title.slice(0, 10)} ${t('This Entry have been resume monitoring successfully')}`
        )
      }
    })
  }

  const onMonitoringActionCallBack = (type: string) => {
    let currentSelectedItems = [...selectedItems]
    const currentItemsIds = currentSelectedItems.map((item: any) => item?.id)
    let currentFiles = [...files]
    let changedItems: any = []
    if (currentSelectedItems?.length > 0) {
      currentSelectedItems.forEach((item: any) => {
        changedItems?.push(item)
        if (type === 'pause') {
          handlePauseEntry(item)
        }
      })
    }
    if (type === 'remove') {
      handleRemoveFromMonitoring(currentItemsIds)
      if (Math.ceil(totalData / countPerPage) === currentPage) {
        loadFiles(currentPage - 1)
      } else {
        loadFiles(currentPage)
      }
      dispatch(setSelectedItems([]))
    }
    if (type === 'pause') {
      changedItems?.forEach((item: any) => {
        currentFiles.forEach((listItem: any) => {
          if (item?.id === listItem?.id) {
            listItem.paused = !item?.paused
          }
        })
      })
    }
    dispatch(setFiles(currentFiles))
  }

  const handleSearchParam = (e: any) => {
    if (e?.target?.value?.length > 2) {
      dispatch(setFilters({...filters, searchParam: e.target.value}))
    } else if (e?.target?.value?.length === 0) {
      dispatch(setFilters({...filters, searchParam: null}))
      window.history.pushState({}, '', getUpdatedUrl({...filters, searchParam: null}, currentPage))
    }
  }

  const debouncedResults = useMemo(() => {
    return debouce(handleSearchParam, 750)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const handleUploadFiles = () => {
    const remains = userInfo?.data?.praxis?.data?.limits?.copyright?.can?.items?.remains
    if (remains === 0) {
      Swal.fire({
        title: t("Your current plan doesn't support more file uploads"),
        text: t('Please consider upgrading your plan.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Go to plans'),
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: '/pages/profile/plans',
          })
        }
      })
    } else {
      dispatch(setShowUploader(true))
    }
    dispatch(setShowUploader(true))
  }

  const onSubmissionCallback = (type: string) => {
    if (filters.countries.length === 0) {
      dispatch(setFilters({...filters, entry: null, submissionType: type}))
      window.history.pushState(
        {},
        '',
        getUpdatedUrl({...filters, entry: null, submissionType: type}, currentPage)
      )
    } else {
      dispatch(setExternalHandleSubmission(type))
      if (type === 'IMAGE') {
        dispatch(setActiveTab(0))
      } else {
        dispatch(setActiveTab(1))
      }
    }
  }

  return [
    handleCountryFilter,
    handleEntryCallBack,
    getFilesType,
    loadEntriesSperately,
    loadBasedOnFilter,
    getFindingDetails,
    handleCurrentFinding,
    whiteListCallBack,
    removeWhiteListCallBack,
    getMapData,
    getTotalStatistics,
    getExtraFilterTags,
    onChangeCallBack,
    onMonitoringActionCallBack,
    debouncedResults,
    handleUploadFiles,
    onSubmissionCallback,
  ]
}

export default useMonitoringHook
