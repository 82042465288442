import React from 'react'

function SwitchInputSection({name, checked, title, onChange}: any) {
  return (
    <div className='border-bottom border-secondary mb-4 pb-4'>
      <label className='form-check form-switch form-check-custom form-check-solid'>
        <input
          className='form-check-input w-30px h-20px'
          type='checkbox'
          checked={checked}
          name={name}
          onChange={onChange}
        />
        <span className='form-check-label text-muted fs-7'>{title}</span>
      </label>
    </div>
  )
}

export default SwitchInputSection
