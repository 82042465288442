import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class FileManagerServices {
  public static async getFileList(parentId?: string): Promise<AxiosResponse> {
    try {
      if (parentId) return await axiosSettings.get<null, AxiosResponse>(`/v1/folders/${parentId}`)
      else
        return await axiosSettings.get<null, AxiosResponse>(
          `/v1/folders?orderBy=created_at&sortedBy=desc`
        )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async createFile(name: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('name', name)

    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/folders', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async createSubFile(name: string, parentId: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('name', name)
    params.append('parent_id', parentId)

    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/folders', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateFileName(
    newName: string,
    fileId: any,
    parentId: any
  ): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('title', newName)
    parentId !== null && params.append('parent_id', parentId)
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/submissions/${fileId}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateFolderName(
    newName: string,
    fileId: any,
    parentId: any
  ): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('name', newName)
    parentId !== null && params.append('parent_id', parentId)
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/folders/${fileId}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async deleteFile(fileId: any): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>(`/v1/files/${fileId}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async deleteFolder(fileId: any): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>(`/v1/folders/${fileId}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async uploadFile(data: any): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append('key', data.key)
      params.append('url', data.url)
      params.append('name', data.name)
      params.append('title', data.title)
      params.append('subtitle', data.subtitle)
      params.append('folder_id', data.folder_id)
      if (data?.mode) {
        params.append('mode', 'monitoring')
      }
      return await axiosSettings.post<any, AxiosResponse>(`/v1/submissions`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  // file converted to-> important file
  public static async importantFile(
    fileId: any,
    parentId: any,
    isImportant: boolean
  ): Promise<AxiosResponse> {
    try {
      return await axiosSettings.patch<null, AxiosResponse>(
        `v1/submissions/${fileId}?is_important=${isImportant === true ? 0 : 1}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async importantFileList(page?: number): Promise<AxiosResponse> {
    let url = `v1/submissions?include=content,type,meta,owner&search=meta.is_important:1&orderBy=created_at&sortedBy=desc`
    if (page) {
      url = `${url}&page=${page}`
    }
    try {
      return await axiosSettings.get<null, AxiosResponse>(url)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async latestItems(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `v1/submissions?include=content,type,meta,owner&limit=15&orderBy=created_at&sortedBy=desc`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async moveToFile(folderName: string, fileId: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('name', folderName)
    params.append('parent_id', fileId)
    try {
      return await axiosSettings.patch<null, AxiosResponse>('v1', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async changePage(
    query: any,
    pageNumber: number,
    parentId?: string
  ): Promise<AxiosResponse> {
    let url = `/v1/folders?orderBy=created_at&sortedBy=desc&search=${query}&page=${pageNumber}`
    if (parentId)
      url = `/v1/folders/${parentId}?orderBy=created_at&sortedBy=desc&search=${query}&page=${pageNumber}`
    try {
      return await axiosSettings.get<null, AxiosResponse>(url, {data: []})
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async searchItem(query: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/folders?orderBy=created_at&sortedBy=desc&search=${query}`,
        {data: []}
      )
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async searchImportantItem(
    query: string,
    pageNumber?: Number
  ): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/submissions?include=content,type,meta,owner&search=meta.is_important:1;title:${query}&searchJoin=and&orderBy=created_at&sortedBy=desc&page=${pageNumber}`,
        {data: []}
      )
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async downloadDecodedFileById(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/download/files/${id}`, {
        responseType: 'arraybuffer',
      })
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async getMoveChooserList(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/folderslist`, {
        data: [],
      })
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async moveSubmissionsFromRoot(
    folder_id: string,
    files: any
  ): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    if (folder_id) {
      params.append('folder_id', folder_id)
    } else {
      params.append('delete', '1')
    }

    const keys = Object.keys(files)
    let counter = 0
    keys.forEach((key) => {
      if (files[key]) {
        params.append(`submissions[${counter}]`, key)
        counter++
      }
    })

    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/files', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async moveFileAction(folder_id: string, files: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    if (folder_id) {
      params.append('folder_id', folder_id)
    }
    const keys = Object.keys(files)
    keys.forEach((key, idx) => {
      if (files[key]) {
        params.append(`files[${idx}]`, key)
      }
    })
    if (!folder_id) {
      params.append('delete', '1')
    }
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/bulk/files', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async moveFolder(folder_id: string, files: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    if (folder_id) {
      params.append('folder_id', folder_id)
    } else {
      params.append('root', '1')
    }

    const keys = Object.keys(files)
    keys.forEach((key, idx) => {
      if (files[key]) {
        params.append(`folders[${idx}]`, key)
      }
    })
    try {
      return await axiosSettings.post<null, AxiosResponse>('/v1/bulk/folders', params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getSubmissionDetail(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/submissions/${id}?include=owner,type,content,meta,text,post`
      )
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async sendCertificate(id: string, type: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/coo/send/${id}/${type}`)
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async downloadSubmission(id: string, type: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/coo/download/${id}/${type}`, {
        responseType: 'arraybuffer',
      })
    } catch (error: any) {
      throw Object(error?.response)
    }
  }

  public static async handleMonitoring(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.post<null, AxiosResponse>(
        `/v1/defender/entries?submission_id=${id}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
