import CountUp from 'react-countup'

export const getPlanDetail = (currentPlans: any, planName: any) => {
  if (planName === 'copyright') {
    return {
      icon: 'files/fil023.svg',
      id: 'plan_copyright',
      title: 'My Uploads',
      dataInfo: (
        <>
          <h4 className='mb-0 fs-6'>
            <CountUp end={currentPlans?.usage[planName]?.submissions?.total} duration={3} /> /{' '}
            <CountUp end={currentPlans?.tier[planName]?.units} duration={3} />
          </h4>
        </>
      ),
    }
  } else if (planName === 'defender') {
    return {
      icon: 'general/gen018.svg',
      title: 'Monitoring',
      id: 'plan_defender',
      dataInfo: (
        <>
          {' '}
          <h4 className='mb-0 fs-6'>
            <CountUp end={currentPlans?.usage[planName]?.total} duration={3} /> /
            <CountUp end={currentPlans?.tier[planName]?.units} />
          </h4>
        </>
      ),
    }
  } else if (planName === 'whale') {
    return {
      icon: 'general/gen055.svg',
      id: 'plan_whale',
      title: 'MyBlogs',
      dataInfo: (
        <h4 className='mb-0  fs-6'>
          <CountUp end={currentPlans?.usage[planName]?.posts?.total} duration={3} /> /
          <CountUp end={currentPlans?.tier[planName]?.units} />
        </h4>
      ),
    }
  } else if (planName === 'toro') {
    return {
      icon: 'general/gen051.svg',
      id: 'plan_toro',
      title: 'MySocials',
      dataInfo: (
        <h4 className='mb-0  fs-6'>
          <CountUp end={currentPlans?.usage[planName]?.posts?.total} />
          /<CountUp end={currentPlans?.tier[planName]?.units} />
        </h4>
      ),
    }
  }
}
