
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ConfigModel } from "../../../../app/modules/auth/models/ConfigModel";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { AuthenticationServices } from "../../../../services/api_services/AuthenticationServices";


interface IAuthState {
  user?: UserModel //will delete
  accessToken?: string,
  status: string
}

const initialState: IAuthState = {
  user: undefined, 
  accessToken: '',
  status: 'idle'
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    builder
      .addCase(handleLoginService.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(handleLoginService.fulfilled, (state, action) => {
        state.status = 'succeeded'
        localStorage.setItem('access_token' , JSON.stringify(action.payload?.data?.data.access_token))
        state.accessToken = action.payload?.data?.data.access_token
        state.user = action.payload
      })
      .addCase(handleLoginService.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
});

export const handleLoginService: any = createAsyncThunk("/v1/clients/web/login", async (queryParam: any , {getState}) => {
    return AuthenticationServices.login(queryParam.email,queryParam.password);
});


export const {  } = authSlice.actions;

export default authSlice.reducer;