import React from 'react'
import {useTranslation} from 'react-i18next'

function SettingsModalHeader({wordpressBlogDetail, otherBlogDetail}: any) {
  const {t} = useTranslation('translation')
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100'>
      <span className='fw-bold fs-5 text-dark me-2 mb-1 w-100 text-center'>
        {t('Blog Settings')}
      </span>
      <span className='fw-bolder fs-3 text-dark me-2 w-100 text-center'>
        {wordpressBlogDetail?.type === 'wordpress'
          ? wordpressBlogDetail?.blog?.data?.name
          : otherBlogDetail?.blog?.data?.name}
      </span>
    </div>
  )
}

export default SettingsModalHeader
