import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import '../../index.scss'
import BlogSelector from './components/BlogSelector'
import InvalidPlanMessage from './components/InvalidPlanMessage'
import ModalHeader from './components/ModalHeader'
import NewBlogWrapper from './components/NewBlogWrapper'

type Props = {
  show: boolean
  defaultPositionTrigger?: boolean
  handleClose: () => void
  onDefaultCallBack?: any
  addNewBlockCallBack: (e: any) => any
  onPluginInstall: () => void
  onAddQuickblog: (e: any) => any
  BEData: any
}

const NewBlogModal: React.FC<Props> = ({
  show,
  BEData,
  defaultPositionTrigger,
  addNewBlockCallBack,
  onPluginInstall,
  handleClose,
  onDefaultCallBack,
  onAddQuickblog,
}) => {
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const [newBlogForm, setNewBlogForm] = useState('')
  const [addNewBlogDetail, setAddNewBlogDetail] = useState({})

  const history = useHistory()

  const getUserPermission = () => {
    return userInfo?.data?.praxis?.data?.limits?.whale?.can?.items?.submit
  }

  const goToPlanPage = () => {
    history.push('/pages/profile/plans', {id: 'plan_whale'})
  }

  useEffect(() => {
    getUserPermission()
  }, [])

  useEffect(() => {
    if (defaultPositionTrigger) {
      setNewBlogForm('')
      onDefaultCallBack(false)
    }
  }, [defaultPositionTrigger])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={() => {
        handleClose()
        setNewBlogForm('')
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <ModalHeader
          newBlogForm={newBlogForm}
          onclose={() => {
            handleClose()
            setNewBlogForm('')
          }}
        />
        <div className='modal-body'>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-around'>
              {getUserPermission() === true ? (
                <>
                  {newBlogForm === '' ? (
                    <BlogSelector onCallBack={(e: any) => setNewBlogForm(e.target.name)} />
                  ) : (
                    <NewBlogWrapper
                      userInfo={userInfo}
                      newBlogForm={newBlogForm}
                      BEData={BEData}
                      onAddNewBlogDetail={(e: any) => {
                        setAddNewBlogDetail({
                          ...addNewBlogDetail,
                          url: e.target.value,
                          name: newBlogForm,
                          addNewBlockCallBack,
                        })
                      }}
                      onAddQuickblog={onAddQuickblog}
                      onPluginInstall={onPluginInstall}
                      onAddNewBlockCallBack={() => addNewBlockCallBack(addNewBlogDetail)}
                    />
                  )}
                </>
              ) : (
                <InvalidPlanMessage goToPlanPage={goToPlanPage} />
              )}
              <div className='horizontal__space'></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {NewBlogModal}
