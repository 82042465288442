import React from 'react'
import {useTranslation} from 'react-i18next'
import SwitchInputSection from '../SwitchInputSection'

function SwitchInputsWrapper({selectedValues, blogerBlogDetail, onSelectedValues}: any) {
  const {t} = useTranslation('translation')
  console.log('blogerBlogDetail', blogerBlogDetail)
  return (
    <div>
      <SwitchInputSection
        name='defender'
        title={t('Add everything to Monitoring automatically?')}
        checked={selectedValues['defender']}
        onChange={(e: any) => {
          onSelectedValues({...selectedValues, defender: e.target.checked})
        }}
      />
      <SwitchInputSection
        name='get_pages'
        title={t('Protect pages as well')}
        checked={selectedValues['get_pages']}
        onChange={(e: any) => {
          onSelectedValues({...selectedValues, get_pages: e.target.checked})
        }}
      />
      <SwitchInputSection
        name='strip_text'
        title={t('Strip text from tags')}
        checked={selectedValues['strip_text']}
        onChange={(e: any) => {
          onSelectedValues({...selectedValues, strip_text: e.target.checked})
        }}
      />
      {blogerBlogDetail?.type !== 'quickblog' && (
        <SwitchInputSection
          name='only_future'
          title={t('Get only future content.')}
          checked={selectedValues['only_future']}
          onChange={(e: any) => {
            onSelectedValues({...selectedValues, only_future: e.target.checked})
          }}
        />
      )}
    </div>
  )
}

export default SwitchInputsWrapper
