import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'

export default function ProcessingModal({loading}: any) {
  const {t} = useTranslation('translation')
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-100'
      show={loading}
    >
      <div
        className='d-flex justify-content-center align-items-center m-5'
        style={{height: '200px'}}
      >
        <span className='indicator-progress d-flex flex-column align-items-center'>
          <span className='spinner-border spinner-border-xl align-middle ms-2'></span>
          <h5 className='mt-5'>{t('Please wait. Processing')}... </h5>
        </span>
      </div>
    </Modal>
  )
}
