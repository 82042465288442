/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {useEffect} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Modal} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {fetchUserInfo, handleDeleteUserRedux} from '../../setup/reduxslice/slices/user'
import {AuthenticationServices} from '../../services/api_services/AuthenticationServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import VerificationNeeded from '../pages/verification'
import OnBoardingVideo from '../../_metronic/layout/components/onBoarding/onBoardingVideo'
import {useTranslation} from 'react-i18next'
import useLocale from '../../hooks/useLocale'

const Routes = (props: any) => {
  const {t} = useTranslation('translation')
  const userDelete = useSelector((state: any) => state.user.userDelete)

  const isAuthorized = useSelector((state: any) => state.user.userInfo)
  const userInfoStatus = useSelector((state: any) => state.user.status)
  const userLocalization = isAuthorized?.data?.options?.data?.localization
  const token = localStorage.getItem('access_token')
  const history = useHistory()
  const local = useLocale()
  let lang
  if (history?.location?.search?.indexOf('lang') > -1) {
    lang = history?.location?.search?.substring(6, history?.location?.search?.length)
  }
  const currentLocale = local.getLocale()
  const localStorageValue = localStorage.getItem('lang')
  if (lang && lang !== currentLocale) {
    local.setLocale(lang)
    localStorage.setItem('lang', lang)
  } else if (!lang && localStorageValue && localStorageValue !== currentLocale) {
    local.setLocale(localStorageValue)
  }

  if (
    isAuthorized &&
    Object.keys(isAuthorized).length > 0 &&
    (userLocalization === null || userLocalization?.length === 0)
  ) {
    AuthenticationServices.updateUserInfo(isAuthorized?.data?.id, {
      options: {localization: localStorageValue},
    })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfoStatus === 'idle' && !!token) {
      dispatch(fetchUserInfo())
    }
    if (userDelete === undefined) {
      dispatch(handleDeleteUserRedux(isAuthorized?.data?.has_requested_for_delete))
    }
  }, [userInfoStatus, dispatch, userDelete, token, isAuthorized])

  const handleDeleteAccount = async () => {
    await AuthenticationServices.updateUser(isAuthorized.data?.id, false)
      .then((res) => {
        toast('Your account has been activated', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'success',
        })
        dispatch(handleDeleteUserRedux(false))
      })
      .catch((res) => {
        toast('Your account could not be activated', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'info',
        })
      })
      .finally(() => {
        dispatch(fetchUserInfo())
      })
  }

  if ((userInfoStatus === 'idle' || userInfoStatus === 'loading') && !!!isAuthorized && !!token) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '300px',
            height: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            width={84}
            height={84}
            src={toAbsoluteUrl('/media/copyrightsworld/logo/tb_logo_sign_sm.png')}
            alt='Start logo'
          />
          <span className='mt-5 pl-2'>Loading ...</span>
        </div>
      </>
    )
  }
  if (token === null || token === undefined) {
    if (
      history?.location?.pathname?.indexOf('auth') === -1 &&
      history?.location?.pathname?.indexOf('login') === -1
    ) {
      localStorage.setItem(
        'came_from',
        `${history?.location?.pathname}${history?.location?.search}`
      )
    }

    return (
      <Route>
        <AuthPage />
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
      </Route>
    )
  } else if (!isAuthorized) {
    return (
      <Route>
        <AuthPage />
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
      </Route>
    )
  } else if (userInfoStatus === 'succeeded') {
    return (
      <>
        <VerificationNeeded />
        <OnBoardingVideo />
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        <MasterLayout>
          <PrivateRoutes />
          <Modal
            id='delete-to-account-modal'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-1000px h-auto'
            show={userDelete}
            onHide={() => {
              toast("You have deleted your account, you can't do any action!", {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'info',
              })
            }}
          >
            <div className='container-xl px-10 py-10'>
              <div className='modal-header py-2 d-flex justify-content-between border-0'>
                <h3>{t('Your account will be deleted in 5 days!')}</h3>
              </div>

              <p className='px-7 '>
                {t(
                  'You have 5 days to re-activate your account. After that period this action will be irreversible.'
                )}
              </p>

              <div className='d-flex justify-content-end'>
                <button className='ml-5 btn btn-success' onClick={handleDeleteAccount}>
                  Re-activate my account
                </button>
              </div>
            </div>
          </Modal>
        </MasterLayout>
      </>
    )
  } else return <></>
}

export default Routes
