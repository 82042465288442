import React from 'react'
import {useHistory} from 'react-router'
import {KTSVG} from '../../../../../../../_metronic/helpers'

function ButtonWrapper({id, title, icon, note, isItInvalidPlan, onCreateAccount}: any) {
  const history = useHistory()

  const goToPlanPage = () => {
    history.push('/pages/profile/plans', {id: id})
  }

  return (
    <div
      className='d-flex justify-content-between w-100 align-items-center cursor-pointer bg-hover-light-primary'
      onClick={() => {
        isItInvalidPlan ? goToPlanPage() : onCreateAccount()
      }}
    >
      <div className='fs-4 fw-bolder align-items-center d-flex'>
        <span className='bullet bullet-vertical h-40px bg-primary me-2'></span>
        <KTSVG path={`/media/icons/${icon}`} className='svg-icon-2x me-2' />
        {isItInvalidPlan ? note : title}
      </div>
      <button className='btn'>
        <KTSVG path='/media/icons/duotune/arrows/arr017.svg' className='svg-icon-2x text-primary' />
      </button>
    </div>
  )
}

export default ButtonWrapper
