import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import OnBoardingModalContent from '../../../../../../../_metronic/layout/components/onBoarding/onBoardingVideo/onBoardingModalContent'

function ActionButton({color, icon, title, onCallBack}: any) {
  return (
    <div
      className='d-flex align-items-center w-100 cursor-pointer bg-hover-light-primary py-1 rounded'
      onClick={onCallBack}
    >
      <KTSVG
        path={`/media/icons/${icon}`}
        className={`svg-icon-2x svg-icon-${color} d-block me-2`}
      />
      <p className='text-gray-800 fw-bolder fs-6 mb-0'>{title}</p>
    </div>
  )
}

export default function ProfileCardActions() {
  const [isOpen, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  const history = useHistory()
  return (
    <div className='d-flex flex-column w-100 align-items-start gap-2'>
      <ActionButton
        title='My Profile'
        icon='duotune/communication/com006.svg'
        onCallBack={() => history.push('/pages/profile/overview')}
      />
      <ActionButton
        title='Plans'
        icon='duotune/abstract/abs029.svg'
        onCallBack={() => history.push('/pages/profile/plans')}
      />
      <ActionButton
        title='Redeem coupon'
        icon='duotune/general/gen017.svg'
        onCallBack={() => history.push('/pages/profile/appsumo')}
      />
      <ActionButton title='Help' icon='duotune/custom/help.svg' onCallBack={() => setOpen(true)} />
      <Modal
        id='move-to-folder-modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-1000px h-auto'
        show={isOpen}
        onHide={onClose}
      >
        <OnBoardingModalContent onClose={onClose} onDontShowAgain={() => setOpen(false)} />
      </Modal>
    </div>
  )
}
