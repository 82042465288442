import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class SocialService {
  public static async getAccounts(currentPage: number): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/toro/accounts?include=social,meta&orderBy=created_at&sortedBy=desc&page=${currentPage}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async createAccount(type: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('type', type)
    params.append('redirect', `${window.location.origin}/social-toro?success=true`)
    try {
      return await axiosSettings.post<null, AxiosResponse>(`/v1/toro/access`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async refreshAccount(account: any): Promise<AxiosResponse> {
    const currentType = () => {
      return account?.social?.data?.object === 'ToroFacebook' ? 'facebook' : 'instagram'
    }
    var params = new URLSearchParams()
    params.append('type', currentType())
    params.append('redirect', `${window.location.origin}/social-toro`)
    params.append('account_id', account?.id)
    try {
      return await axiosSettings.post<null, AxiosResponse>(`/v1/toro/access`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async pausedAccount(id: string, value: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('is_paused', value)
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/toro/accounts/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async updateSettings(id: string, value: any): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('settings[fetch_type]', value?.fetch_type)
    params.append('settings[handle_type]', value?.handle_type)
    params.append('settings[defender]', value?.defender === true ? '1' : '0')
    params.append('settings[only_future]', value?.only_future === true ? '1' : '0')
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/toro/accounts/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async hideAccount(id: string, value: string): Promise<AxiosResponse> {
    var params = new URLSearchParams()
    params.append('is_hidden', value)
    try {
      return await axiosSettings.patch<null, AxiosResponse>(`/v1/toro/accounts/${id}`, params)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async DeleteAccount(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.delete<null, AxiosResponse>(`/v1/toro/accounts/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async GetPosts(id: string, params?: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(
        `/v1/toro/posts/social/${id}?include=submissions&orderBy=created_at&sortedBy=desc&${params}`
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
