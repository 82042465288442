/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {FileManagerServices} from '../../../../services/api_services/FileManagerServices'
import {KTSVG} from '../../../../_metronic/helpers'
import {NotifyError, NotifySuccess} from '../../../Helpers/Toast'
import FileManagerImageModal from './FileManagerImageModal'
import {faceBookIcon, instagramIcon, wordpressIcon} from './helper'
import './index.scss'
import SecurityBagdeModal from './SecurityBagdeModal'

const SidePanel: any = (props: any) => {
  const {t} = useTranslation('translation')
  const [viewModal, setViewModal] = useState(false)
  const [securtyBadgeModal, setSecurtyBadgeModal] = useState(false)

  const sendCertificate = (type: any) => {
    FileManagerServices.sendCertificate(props.sidePanelData?.id, type)
      .then((res) => {
        NotifySuccess(t('Successfully send!'))
      })
      .catch((e) => {
        NotifyError(t('Not available for this file!'))
      })
  }
  const downloadSubmission = (type: string) => {
    FileManagerServices.downloadSubmission(props.sidePanelData?.id, type)
      .then((res) => {
        const fileName = res?.headers['content-disposition']?.replace('attachment; filename=', '')
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
        NotifySuccess(t('Your Certificate has been downloaded!'))
      })
      .catch((e) => {
        NotifyError(t('Error occured!'))
      })
  }

  const getFileSize = (Num: any) => {
    if (Num < 1000) return Num + ' Bytes'
    Num = ('0'.repeat(((Num += '').length * 2) % 3) + Num).match(/.{3}/g)
    return Number(Num[0]) + '.' + Num[1].substring(0, 2) + ' ' + '  kMGTPEZY'[Num.length] + 'B'
  }

  const handlesidebarPosition = () => {
    if (props?.sidePanelData?.post) {
      if (props?.sidePanelData?.post?.data?.preview_image) {
        setViewModal(true)
      } else {
        window.open(props?.sidePanelData?.post?.data?.permanent_url)
      }
    } else {
      setViewModal(true)
    }
  }

  const getIcon = () => {
    if (props?.submissionThumb) {
      return (
        <svg style={{width: '100%', height: '100%'}}>
          <image xlinkHref={props.submissionThumb} width='100%' height='100%' />
        </svg>
      )
    } else if (props?.sidePanelData?.source === 'ToroFacebook') {
      return faceBookIcon()
    } else if (props?.sidePanelData?.source === 'ToroInstagram') {
      return instagramIcon()
    } else if (props?.sidePanelData?.source === 'Wordpress') {
      return wordpressIcon()
    } else {
      return (
        <span className='svg-icon svg-icon-20x svg-icon-primary me-4'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              opacity='0.3'
              d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z'
              fill='black'
            ></path>
            <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='black'></path>
          </svg>
        </span>
      )
    }
  }

  return (
    <div
      className='position-fixed top-0 end-0 mh-100'
      id={`${props?.sidePanelToogle ? 'sidepanel_open' : 'sidepanel_close'}`}
    >
      <div style={{height: '100%'}} className='card w-100 rounded-0 mh-100'>
        <div className='card-header pe-5 d-flex flex-row' id='kt_drawer_chat_messenger_header'>
          <div className='card-title' style={{float: 'left', width: '80%'}}>
            <div
              className='d-flex justify-content-center flex-column me-3'
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <a
                href='#'
                className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1'
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.sidePanelData?.title}
              </a>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='me-2'></div>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              onClick={() => props.handleClose()}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <div className='d-flex justify-content-around flex-row mt-5'>
              <Tab>
                <button className='btn btn-light-primary' data-kt-menu-trigger='click'>
                  <i className='bi bi-file-earmark fs-3'></i>
                  {t('Details')}
                </button>
              </Tab>
              <Tab>
                <button className='btn btn-light-primary' data-kt-menu-trigger='click'>
                  <i className='bi bi-gear-wide-connected fs-3'></i>
                  {t('Actions')}
                </button>
              </Tab>
            </div>
          </TabList>
          <TabPanel>
            <div className='overlay-wrapper px-18 mt-5 text-center'>{getIcon()}</div>
            <div className='d-flex justify-content-center mt-5'>
              {(props?.submissionThumb || props?.sidePanelData?.post) && (
                <div className='d-flex gap-3'>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => handlesidebarPosition()}
                  >
                    {t('Full View')}
                  </button>
                  <a
                    className='btn btn-success btn-sm'
                    href={
                      props.submissionThumb ||
                      props?.sidePanelData?.post?.data?.preview_image ||
                      props?.sidePanelData?.post?.data?.permanent_url
                    }
                    download='logo'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('Download File')}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr091.svg'
                      className='svg-icon-2 ms-2'
                    />
                  </a>
                </div>
              )}
            </div>
            <p style={{padding: '20px 40px'}}>
              {t("File's Signature")}: {props.sidePanelData?.fingerprint}
            </p>
            <div className='separator my-10'></div>
            <div className='d-flex justify-content-center flex-column px-10'>
              <div className='fs-3 fw-bolder my-2'>{t('ASSET DETAILS')}</div>
              <div className='card-title' style={{float: 'left', width: '100%'}}>
                <div
                  className='d-flex justify-content-start me-3 align-items-center'
                  style={{
                    width: '100%',
                  }}
                >
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('Title')}:</span>
                  <span
                    className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize'
                    style={{
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    data-bs-toggle='tooltip'
                    title={props.sidePanelData?.title}
                  >
                    {props.sidePanelData?.title}
                  </span>
                </div>
              </div>
              {props.sidePanelData?.subtitle && (
                <div className='card-title my-3' style={{float: 'left', width: '100%'}}>
                  <div
                    className='d-flex justify-content-start me-3 align-items-center'
                    style={{
                      width: '100%',
                    }}
                    data-bs-toggle='tooltip'
                    title={props.sidePanelData?.subtitle}
                  >
                    <span className='fs-4 fw-bolder mb-0 me-2 '>{t('SubTitle')}:</span>
                    <span
                      className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize'
                      style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {props.sidePanelData?.subtitle}
                    </span>
                  </div>
                </div>
              )}
              <div className='d-flex justify-content-between flex-row my-3'>
                <div className='d-flex'>
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('File Size')}: </span>
                </div>
                <div className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize '>
                  {getFileSize(props.sidePanelData?.content?.data?.file_size)}
                </div>
              </div>
              <div className='d-flex justify-content-between flex-row my-3'>
                <div className='d-flex'>
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('File Type')}: </span>
                </div>
                <div className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize '>
                  {props.sidePanelData?.type?.data?.name}{' '}
                </div>
              </div>
              <div className='d-flex justify-content-between flex-row my-3'>
                <div className='d-flex'>
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('File Name')}: </span>
                </div>
                <div className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize mb-0 aling-items-center'>
                  {props.sidePanelData?.content?.data?.file_name}{' '}
                </div>
              </div>{' '}
              <div className='d-flex justify-content-between flex-row my-3'>
                <div className='d-flex'>
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('Created at')}: </span>
                </div>
                <div className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize '>
                  {props?.sidePanelData?.readable_created_at}{' '}
                </div>
              </div>
              <div className='d-flex justify-content-between flex-row my-3'>
                <div className='d-flex'>
                  <span className='fs-4 fw-bolder mb-0 me-2 '>{t('Submitted by')}: </span>
                </div>
                <div className='fs-6 text-gray-900 text-hover-primary me-1 lh-1 text-capitalize '>
                  {props.sidePanelData.owner?.data?.last_name +
                    ' ' +
                    props.sidePanelData.owner?.data?.name}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='d-flex justify-content-center flex-column px-10'>
              <br></br>
              <button
                disabled={!props?.sidePanelData?.in_chain}
                className='btn btn-light-primary'
                onClick={() => downloadSubmission('pdf')}
              >
                <i className='bi bi-cloud-arrow-down-fill fs-3'></i>
                {t('Download Certificate - PDF')}
              </button>
              <br></br>
              <button
                disabled={!props?.sidePanelData?.in_chain}
                className='btn btn-light-primary'
                onClick={() => downloadSubmission('image')}
              >
                <i className='bi bi-cloud-arrow-down-fill fs-3'></i>
                {t('Download Certificate - Image')}
              </button>
              <br></br>
              <button
                className='btn btn-light-primary'
                data-kt-menu-trigger='click'
                onClick={() => setSecurtyBadgeModal(!securtyBadgeModal)}
              >
                <i className='bi bi-shield-shaded fs-3'></i>
                {t('Get Security Badge')}
              </button>
              <br></br>
              <button
                disabled={!props?.sidePanelData?.in_chain}
                className='btn btn-light-primary'
                onClick={() => sendCertificate('pdf')}
              >
                <i className='bi bi-envelope-fill fs-3'></i>
                {t('Send Certificate Via Email as PDF')}
              </button>
              <br></br>
              <button
                disabled={!props?.sidePanelData?.in_chain}
                className='btn btn-light-primary'
                onClick={() => sendCertificate('image')}
              >
                <i className='bi bi-envelope-fill fs-3'></i>
                {t('Send Certificate Via Email as Image')}
              </button>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
      <FileManagerImageModal
        data={props.submissionThumb}
        sidePanelData={props?.sidePanelData}
        show={viewModal}
        handleClose={() => setViewModal(false)}
      />
      <SecurityBagdeModal
        show={securtyBadgeModal}
        sidePanelData={props?.sidePanelData}
        handleClose={() => setSecurtyBadgeModal(!securtyBadgeModal)}
      />
    </div>
  )
}

export {SidePanel}
