import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'
import {NotifyError} from '../../app/Helpers/Toast'
import {getExtraFilterUrl} from '../../app/pages/live-monitoring/helper'

export class DefenderServices {
  public static async getDefenders(
    pageNumber?: number,
    showOnlyMatches?: boolean,
    latestItems?: boolean,
    searchParam?: string,
    type?: string
  ): Promise<AxiosResponse> {
    let urlToUse = `/v1/defender/entries`
    const submissionType = type?.toLowerCase()
    const sortQuery = 'orderBy=created_at&sortedBy=desc'
    const searchQuery = `search=submission.title:${searchParam}`
    const submissionTypeQuery = `submission.content.file_type:${submissionType}`
    if (showOnlyMatches) {
      urlToUse = `${urlToUse}?onlyfindings`
      if (pageNumber) urlToUse = `${urlToUse}&page=${pageNumber}`
      if (latestItems) urlToUse = `${urlToUse}&${sortQuery}`
      if (searchParam && !submissionType) urlToUse = `${urlToUse}&${searchQuery}`
      if (searchParam && submissionType)
        urlToUse = `${urlToUse}&${searchQuery};searchFields=${submissionTypeQuery}`
      if (!searchParam && submissionType) urlToUse = `${urlToUse}&search=${submissionTypeQuery}`
    } else if (!showOnlyMatches && pageNumber) {
      urlToUse = `${urlToUse}?page=${pageNumber}`
      if (latestItems) urlToUse = `${urlToUse}&${sortQuery}`
      if (searchParam && !submissionType) urlToUse = `${urlToUse}&${searchQuery}`
      if (searchParam && submissionType)
        urlToUse = `${urlToUse}&${searchQuery};searchFields=${submissionTypeQuery}`
      if (!searchParam && submissionType) urlToUse = `${urlToUse}&search=${submissionTypeQuery}`
    } else if (!showOnlyMatches && !pageNumber && latestItems) {
      urlToUse = `${urlToUse}?${sortQuery}`
      if (searchParam && !submissionType) urlToUse = `${urlToUse}&${searchQuery}`
      if (searchParam && submissionType)
        urlToUse = `${urlToUse}&${searchQuery};searchFields=${submissionTypeQuery}`
      if (!searchParam && submissionType) urlToUse = `${urlToUse}&search=${submissionTypeQuery}`
    } else if (!showOnlyMatches && !pageNumber && !latestItems && searchParam) {
      if (searchParam && !submissionType) urlToUse = `${urlToUse}?${searchQuery}`
      if (searchParam && submissionType)
        urlToUse = `${urlToUse}?${searchQuery};searchFields=${submissionTypeQuery}`
      if (!searchParam && submissionType) urlToUse = `${urlToUse}?search=${submissionTypeQuery}`
    } else if (!showOnlyMatches && !pageNumber && !latestItems && !searchParam && submissionType) {
      urlToUse = `${urlToUse}?search=${submissionTypeQuery}`
    }

    try {
      return await axiosSettings.get<null, AxiosResponse>(urlToUse)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async pausedEntry(id: string, value: boolean): Promise<AxiosResponse> {
    let urlToUse = `/v1/defender/entries/${id}`
    var params = new URLSearchParams()
    params.append('paused', value === true ? '1' : '0')
    try {
      return await axiosSettings.patch<null, AxiosResponse>(urlToUse, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async whitelistEntryAdd(id: string, domainIds: string[]): Promise<AxiosResponse> {
    let urlToUse = `/v1/defender/entries/${id}`
    var params = new URLSearchParams()
    domainIds.forEach((domain, idx) => {
      params.append(`whitelist[${idx}]`, domain)
    })
    if (domainIds.length === 0) {
      params.append('whitelist', '[]')
    }
    try {
      return await axiosSettings.patch<null, AxiosResponse>(urlToUse, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async whitelistEntryRemove(
    id: string,
    domainIds: string[]
  ): Promise<AxiosResponse> {
    let urlToUse = `/v1/defender/entries/${id}${domainIds.length === 0 ? '?clear_whitelist=1' : ''}`
    var params = new URLSearchParams()
    domainIds.forEach((domain, idx) => {
      params.append(`whitelist[${idx}]`, domain)
    })
    if (domainIds.length === 0) {
      params.append('', '')
    }
    try {
      return await axiosSettings.patch<null, AxiosResponse>(urlToUse, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getDefenderFindingDetail(
    id: any,
    entryId: string,
    type: any
  ): Promise<AxiosResponse> {
    try {
      let urlToUse = `v1/defender/entry_findings/${type}/${entryId}/${id}`
      return await axiosSettings.get<null, AxiosResponse>(urlToUse)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getDefenderDetail(
    id: any,
    type: any,
    countries?: any[],
    extraFilters?: {},
    pageNumber?: number
  ): Promise<AxiosResponse> {
    const currentExtraFilterUrl = getExtraFilterUrl(extraFilters)
    try {
      let urlToUse = `/v1/defender/entry_findings/${type}/${id}?include=entry`
      if (currentExtraFilterUrl?.length > 0) {
        urlToUse = `${urlToUse}&${currentExtraFilterUrl}`
      }
      if (countries && countries.length > 0) {
        urlToUse = `${urlToUse}&search=domain.country.short:${countries.join(',').toLowerCase()}`
      }
      if (pageNumber) urlToUse = `${urlToUse}&page=${pageNumber}`
      return await axiosSettings.get<null, AxiosResponse>(urlToUse)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getDefenderFilesByType(
    type: any,
    countries?: any[],
    extraFilters?: any,
    pageNumber?: number
  ): Promise<AxiosResponse> {
    const currentExtraFilterUrl = getExtraFilterUrl(extraFilters)
    try {
      let urlToUse = `/v1/defender/findings/${type}?include=entry`
      if (currentExtraFilterUrl?.length > 0) {
        urlToUse = `${urlToUse}&${currentExtraFilterUrl}`
      }
      if (countries && countries.length > 0) {
        urlToUse = `${urlToUse}&search=domain.country.short:${countries.join(',').toLowerCase()}`
        if (pageNumber) {
          urlToUse = `${urlToUse}&page=${pageNumber}`
        }
      } else {
        if (pageNumber) urlToUse = `${urlToUse}&page=${pageNumber}`
      }
      return await axiosSettings.get<null, AxiosResponse>(urlToUse)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getDefenderStatistics(): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/defender/statistics`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async UpdateFinding(
    id: string,
    type: string,
    fileType: string,
    value: number
  ): Promise<AxiosResponse> {
    try {
      var params = new URLSearchParams()
      params.append(type, value.toString())
      return await axiosSettings.patch<null, AxiosResponse>(
        `/v1/defender/entry_findings/${fileType}/${id}`,
        params
      )
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async GetEntryById(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/defender/entries/${id}`)
    } catch (error: any) {
      NotifyError(error?.response?.data?.message)
      throw Object(error.response)
    }
  }

  public static async RemoveEntry(entriesIds: any): Promise<AxiosResponse> {
    let urlToUse = '/v1/bulk/entries'
    var params = new URLSearchParams()
    entriesIds.forEach((id: any, index: number) => {
      params.append(`ids[${index}]`, id)
    })
    try {
      return await axiosSettings.post<null, AxiosResponse>(urlToUse, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
    } catch (error: any) {
      NotifyError(error?.response?.data?.message)
      throw Object(error.response)
    }
  }

  public static async GetEntryDetails(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/defender/entry_findings/image/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }

  public static async getEntryStatistics(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/defender/statistics/${id}`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
