import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../_metronic/helpers'

export default function VerifyModal({verifyData, show, handleClose, verifyBlogCallBack}: any) {
  const {t} = useTranslation('translation')
  const [copyVerifyCode, setCopyVerifyCode] = useState(false)
  const copyClipboard = (e: string) => {
    navigator.clipboard.writeText(e)
    setCopyVerifyCode(true)
  }

  const handelVerifyBlogData = () => {
    let currentVerifyData = verifyData['newblogdetail']
    currentVerifyData['signature'] = verifyData?.verifydata?.signature
    verifyBlogCallBack(currentVerifyData)
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      show={show}
      onHide={() => {
        handleClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-between border-0'>
          {t('Ownership verification using tag')}
        </div>
        <div className='modal-body bg-light-primary rounded'>
          <ul>
            <li className='mb-3'>{t('1- Go to your wordpress Dashboard')}</li>
            <li className='d-flex flex-column mb-3'>
              {t('2- Add a new tag with name & slug')} <br />
              <span
                role='button'
                className='fw-bold fs-6 text-dark me-2 text-hover-primary mb-2'
                onClick={(e) => copyClipboard(verifyData?.verifydata?.tag)}
              >
                {verifyData?.verifydata?.tag} ({t('Click and copy')})
              </span>
              {copyVerifyCode && (
                <h5 className='fw-bold fs-4 text-success mb-2'>{t('Tag Copied')}...</h5>
              )}
              ({t('To add a tag')}{' '}
              <a
                href={verifyData?.verifydata?.url}
                target='_blank'
                rel='noreferrer'
                style={{display: 'contents'}}
              >
                {t('click here')}
              </a>{' '}
              {t('OR go manually to your WordPress panel then Posts-Tags.')})
            </li>
            <li className='mb-3'>
              {t("3- That's it ! Click the button below to verify your ownership!")}
            </li>
          </ul>
          <div className='d-flex justify-content-end'>
            <button
              className='btn-sm btn-primary text-white fs-5'
              onClick={() => handelVerifyBlogData()}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen048.svg'
                className='svg-icon-light svg-icon-2x me-3'
              />
              {t('Verify')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
