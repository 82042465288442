/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {SkeletonServices} from '../../../../services/api_services/SkeletonServices'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {handleRegisterService} from '../../../../setup/reduxslice/slices/user'
import {useTranslation} from 'react-i18next'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import VerifyHumanModal from './VerifyHumanModal'
import ShowPassword from './ShowPassword'

const lang = localStorage.getItem('lang')

const initialValues = {
  firstname: '',
  lastname: '',
  country_id: '',
  email: '',
  password: '',
  changepassword: '',
  accept_newsletters: false,
  accept_tos: false,
  localization: lang ? lang : 'en',
}

export function Registration() {
  const {t} = useTranslation('translation')
  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, t('Minimum 3 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .required(t('First name is required')),
    country_id: Yup.string().required(t('Country is required')),
    email: Yup.string()
      .email(t('Wrong email format'))
      .min(3, t('Minimum 3 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .required(t('Email is required')),
    lastname: Yup.string()
      .min(3, t('Minimum 3 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .required(t('Last name is required')),
    password: Yup.string()
      .min(8, t('Minimum 8 symbols'))
      .max(50, t('Maximum 50 symbols'))
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('Must Contain 8 Characters, One Letter, One Number and One Special Case Character')
      )
      .required(t('Password is required')),
    changepassword: Yup.string()
      .required(t('Password confirmation is required'))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          t("Password and Confirm Password didn't match")
        ),
      }),
    accept_tos: Yup.bool().required(t('You must accept the terms and conditions')),
    accept_newsletters: Yup.bool(),
  })

  const [loading, setLoading] = useState(false)
  const [countryList, setCountryList] = useState([])
  const dispatch = useDispatch()
  const registerStatus = useSelector((state: any) => state.user.status)
  const [verifyModalVisible, setVerifyModalVisible] = useState(true)
  const [verifyToken, setVerifyToken] = useState(undefined) as any
  const [passwordInputType, setPasswordInputType] = useState('password')

  const SiteKey = '6LekDbUhAAAAAIQGfGsCvuC4jPMaNJlYY_h8J6ED'

  //init
  useEffect(() => {
    SkeletonServices.getCountryList().then((response) => {
      setCountryList(response.data)
    })
  }, [])
  function handleCountryOrderByName(a: any, b: any) {
    return a.props.children < b.props.children ? -1 : a.props.children > b.props.children ? 1 : 0
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      dispatch(handleRegisterService(values))
    },
  })

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={SiteKey}>
        <VerifyHumanModal
          show={verifyModalVisible}
          handleClose={() => setVerifyModalVisible(false)}
          onVerify={(e: string) => {
            setVerifyToken(e)
          }}
        />
      </GoogleReCaptchaProvider>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>{t('Create an Account')}</h1>
          <Link to='/auth/login' className='link-primary fw-bolder'>
            {t('Go to login page')}
          </Link>
        </div>
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <label className='form-label fw-bolder text-dark fs-6'>{t('First name')}</label>
            <input
              placeholder={t('First name')}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>{t('Last name')}</label>
              <input
                placeholder={t('Last name')}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('lastname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    'is-valid': formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastname}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>{t('Country')}</label>
          <select
            className='form-select form-select-solid form-select-lg fw-bold'
            {...formik.getFieldProps('country_id')}
          >
            <option value=''>{t('Select a Country...')}</option>
            {countryList
              .map((item: any) => {
                return <option value={item.id}>{item.name}</option>
              })
              .sort(handleCountryOrderByName)}
          </select>
          {formik.touched.country_id && formik.errors.country_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.country_id}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>{t('Email')}</label>
          <input
            placeholder={t('Email')}
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>{t('Password')}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex align-items-center position-relative my-1 mb-3 mb-lg-1'>
                <input
                  type={passwordInputType}
                  placeholder={t('Password')}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <ShowPassword
                  passwordInputType={passwordInputType}
                  onPasswordInputType={(e: any) => setPasswordInputType(e)}
                />
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>{t('Confirm Password')}</label>
          <input
            type='password'
            placeholder={t('Password confirmation')}
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_update_agree'
              {...formik.getFieldProps('accept_newsletters')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_update_agree'
            >
              {t(
                'I would like to receive monthly product updates and inside information about your products.'
              )}
            </label>
          </div>
        </div>
        <div className='fv-row mb-10'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('accept_tos')}
            />

            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_toc_agree'
            >
              {t('I have read and agreed with the')}{' '}
              <a
                href='https://www.toroblocks.com/terms-of-service'
                target='_blank'
                className='ms-1 link-primary'
                rel='noreferrer'
              >
                {t('Terms of Service')}{' '}
              </a>
              {t('and')}
              <a
                href='https://www.toroblocks.com/privacy-policy'
                target='_blank'
                className='ms-1 link-primary'
                rel='noreferrer'
              >
                {t('Privacy Policy')}
              </a>
              .
            </label>
            {formik.touched.accept_tos && formik.errors.accept_tos && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.accept_tos}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={registerStatus === 'loading' || !formik.values.accept_tos}
          >
            {registerStatus !== 'loading' && <span className='indicator-label'>{t('Submit')}</span>}
            {registerStatus === 'loading' && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {t('Please wait...')}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {t('Cancel')}
            </button>
          </Link>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}
