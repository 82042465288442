import {useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'

export function Logout() {
  useEffect(() => {
    localStorage.removeItem('access_token')

    document.location.reload()
  })

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
