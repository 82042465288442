import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {BlogService} from '../../../../services/api_services/BlogServices'

interface IAuthState {
  blogPost?: any
  status: string
}

const initialState: IAuthState = {
  blogPost: [],
  status: 'idle',
}

const blogSlice = createSlice({
  name: 'blogPost',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBlog.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchBlog.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.blogPost = action.payload
      })
      .addCase(fetchBlog.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const fetchBlog: any = createAsyncThunk(
  '/v1/whale/posts/blog/',
  async (id: any, {getState}) => {
    const state: any = getState()
    return BlogService.getBlogPosts(id, state.user.whalePageNumber)
  }
)

export const {} = blogSlice.actions

export default blogSlice.reducer
