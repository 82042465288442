import {combineReducers} from 'redux'

import user from './slices/user'
import auth from './slices/auth'
import config from './slices/config'
import blog from './slices/blog'
import monitoring from './slices/monitoring'
const rootReducer = combineReducers({user, auth, config, blog, monitoring})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
