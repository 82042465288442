import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {AffiliatesService} from '../../../services/api_services/AffiliatesService'

export function AffiliatesCoupon() {
  const {id}: any = useParams()

  const handleClickTrack = async () => {
    const res = await AffiliatesService.trackLink(id)

    if (res.status === 200) {
      window.location.href = 'https://www.toroblocks.com/blocks/'
    } else {
      window.location.href = 'https://www.toroblocks.com/blocks/'
    }
  }

  useEffect(() => {
    localStorage.setItem('affiliate_id', id)
    handleClickTrack()
  }, [])

  return <></>
}
