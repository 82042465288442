import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'

function ModalCloseButton({onHandleClose, onSelectedValues}: any) {
  return (
    <div
      className='btn btn-icon btn-sm btn-light-primary'
      onClick={() => {
        onHandleClose()
        onSelectedValues({})
      }}
    >
      <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
    </div>
  )
}

export default ModalCloseButton
