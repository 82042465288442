import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import HelperModal from './components/HelperModal'

function RowSection({src, title, onSelect}: any) {
  const {t} = useTranslation('translation')
  return (
    <div
      className='d-flex align-items-center w-100 gap-3 cursor-pointer bg-hover-light-primary p-1'
      onClick={onSelect}
    >
      <img className='rounded ob' style={{width: '4rem', objectFit: 'cover'}} src={src} />
      <p className='fs-6 fw-bolder  text-dark mb-0'>{t(title)}</p>
    </div>
  )
}

export default function HelpStoriesCard() {
  const {t} = useTranslation('translation')
  const [selected, setSelected] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  const helperStories = [
    {
      src: 'https://i.ytimg.com/vi_webp/lH4VyK7Mj3s/maxresdefault.webp',
      link: 'https://www.youtube.com/embed/lH4VyK7Mj3s',
      title: 'Full service tour by CEO',
    },
    {
      src: 'https://s3-eu-west-1.amazonaws.com/guidez-thumbnails/p/pyje9nnb5m-8ux23wmt11_full.jpg',
      link: 'https://embed.fleeq.io/l/pyje9nnb5m-8ux23wmt11',
      title: 'How to change your blog settings in myBlogs',
    },
    {
      src: 'https://s3-eu-west-1.amazonaws.com/guidez-thumbnails/p/xl8bt2lfah-z18rxnb7ps_full.jpg',
      link: 'https://embed.fleeq.io/l/xl8bt2lfah-z18rxnb7ps',
      title: 'How to upload and protect your creations',
    },
  ]
  return (
    <div className='card'>
      <div className='fs-3 fw-bold card-header w-100 align-items-center p-5'>
        {t('Help Stories')}
      </div>
      <div className='card-body d-flex flex-column justify-content-center align-items-center gap-2 p-5'>
        {helperStories?.map((item, idx) => {
          return (
            <RowSection
              key={idx}
              src={item?.src}
              link={item?.link}
              title={item?.title}
              onSelect={() => {
                setSelected(item)
                setIsOpen(true)
              }}
            />
          )
        })}
      </div>
      <HelperModal details={selected} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}
