import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {BlogService} from '../../../../services/api_services/BlogServices'
import {NotifyError, NotifySuccess} from '../../../Helpers/Toast'

const useBlogHooks = () => {
  const {t} = useTranslation('translation')
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const [blogList, setBlogList] = useState([] as any)
  const [archideBlogs, setArchideBlogs] = useState([] as any)
  const [activeNewBlogModal, setActiveNewBlogModal] = useState(false)
  const [activeBlogSettingsModal, setActiveBlogSettingsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [BEData, setBEData] = useState({url: ''} as any)
  const [totalData, setTotalData] = useState<number>(1)
  const [countPerPage, setCountPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1 as number)
  const [selectedBlog, setSelectedBlog] = useState({} as any)
  const [selectedBloggerBlog, setSelectedBloggerBlog] = useState({} as any)
  const [selectedWordPressBlog, setSelectedWordPressBlog] = useState({} as any)
  const [allCategories, setAllCategories] = useState([] as any)
  const [wordpressVerifyModal, setWordpressVerifyModal] = useState(false)
  const [wordpressVerifyDetail, setWordpressVerifyDetail] = useState({} as any)
  const [closeModalWithError, setCloseModalWithError] = useState(false)
  const [isSuccessfulConnectionModal, setIsSuccessfulConnectionModal] = useState(false)
  const [addedNewlyBlog, setAddedNewlyBlog] = useState({} as any)

  const history = useHistory()

  const userPlanBlogPermission = userInfo?.data?.praxis?.data?.tier?.whale?.meta?.feature?.url
  const checkUnlimitedPlan = userInfo?.data?.praxis?.data?.tier?.whale?.limited
  const currentConnectedBlog = blogList?.length

  const getBlogList = (isAddedNewBlog?: boolean) => {
    try {
      BlogService.getWhaleAccoutBlogListPage(currentPage).then((res) => {
        const resData = res?.data?.data
        let archideArr: any = []
        let blogArr: any = []
        resData.forEach((element: any) => {
          if (element.is_hidden !== false) {
            archideArr.push(element)
          } else {
            blogArr.push(element)
          }
        })
        setArchideBlogs(archideArr)
        setBlogList(blogArr)
        setTotalData(res?.data?.meta?.pagination?.total)
        setCountPerPage(res?.data?.meta?.pagination?.per_page)
        if (isAddedNewBlog) {
          const currentMostNewCreatedItem = res?.data?.data?.reduce((a: any, b: any) =>
            a?.created_at > b?.created_at ? a : b
          )
          setAddedNewlyBlog(currentMostNewCreatedItem)
          setIsSuccessfulConnectionModal(true)
        }
      })
    } catch (error) {
      console.log('error')
    }
    setLoading(false)
  }

  const getBlogPosts = (blog: any) => {
    try {
      BlogService.getBlogPosts(blog?.id, 1).then((res) => {})
    } catch (error) {
      console.log('error')
      setLoading(false)
    }
  }

  const getAllWorpressCategories = (blog: any) => {
    if (blog?.type === 'wordpress') {
      setLoading(true)
      try {
        BlogService.getAllWorpressCategories(blog?.id).then(
          (res) => {
            const categories = res?.data?.data?.blog?.data?.categories?.data
            setSelectedBloggerBlog({})
            setAllCategories(categories)
            setSelectedWordPressBlog(res?.data?.data)
            setActiveBlogSettingsModal(true)
            setLoading(false)
          },
          (err) => {
            setSelectedBloggerBlog({})
            setActiveBlogSettingsModal(true)
            setLoading(false)
            setSelectedWordPressBlog(blog)
          }
        )
      } catch (error) {
        console.log('error add new blog')
        setLoading(false)
      }
    } else {
      setSelectedWordPressBlog({})
      setAllCategories([])
      setSelectedBloggerBlog(blog)
      setActiveBlogSettingsModal(true)
    }
  }

  const addNewWordpressBLog = async (currentDetails: any, newBlogDetail: any) => {
    try {
      await BlogService.addWordpressBlog(
        currentDetails?.url,
        currentDetails?.redirect,
        newBlogDetail?.signature
      ).then(
        (res) => {
          if (res?.data?.verify === 'tag') {
            setLoading(false)
            setWordpressVerifyModal(true)
            setWordpressVerifyDetail({
              verifydata: res.data,
              newblogdetail: newBlogDetail,
            })
            setCloseModalWithError(true)
          } else {
            const url = res?.data?.url
            if (res?.data?.verify) {
              openUrl(url)
            }
            getBlogList(true)
            setWordpressVerifyModal(false)
            setLoading(false)
            setWordpressVerifyDetail({})
            setCloseModalWithError(true)
          }
        },
        (err) => {
          if (err?.data) {
            setLoading(false)
            const msg = `*${err?.data?.errors?.url[0]} *${err?.data?.message}`
            NotifyError(msg, 6000)
            setCloseModalWithError(true)
            setBEData({
              msg: err.data.errors.plugin,
              url: err.data.errors.url,
              mainUrl: newBlogDetail?.url,
            })
          } else if (err) {
            setCloseModalWithError(true)
            setLoading(false)
            NotifyError(err?.message)
          }
        }
      )
    } catch (error: any) {
      NotifyError(error?.message)
      setLoading(false)
    }
  }

  const addNewBlogSpot = async (currentDetails: any) => {
    try {
      await BlogService.addGoogleBlog(currentDetails?.url, currentDetails?.redirect).then((res) => {
        const url = res?.data?.url
        if (url) {
          openUrl(url)
          setCloseModalWithError(true)
        } else {
          NotifyError(res?.data)
        }
        getBlogList(true)
      })
    } catch (error: any) {
      const msg = `*${error?.data?.errors?.url[0]} *${error?.data?.message}`
      setCloseModalWithError(true)
      NotifyError(msg, 6000)
      setLoading(false)
    }
  }

  const onAddQuickblog = async (details: any) => {
    setActiveNewBlogModal(!activeNewBlogModal)
    setLoading(true)
    try {
      await BlogService.addQuickBlog(details).then((res) => {
        setLoading(false)
        NotifySuccess(t('Blog has been added'))
        setIsSuccessfulConnectionModal(true)
        getBlogList(true)
      })
    } catch (error: any) {
      NotifyError(error?.data?.message, 6000)
      setLoading(false)
    }
  }

  const addNewBlog = (newBlogDetail: any) => {
    setBEData({})
    if (!newBlogDetail.signature) {
      setActiveNewBlogModal(!activeNewBlogModal)
      newBlogDetail.redirect = `${window.location.origin}/web-whale`
      setLoading(true)
    }
    let currentDetails: any = newBlogDetail
    const checkCorrectLink = newBlogDetail?.url.search('http')
    if (checkCorrectLink === -1) {
      currentDetails['url'] = 'http://' + currentDetails?.url
    }
    if (newBlogDetail?.name === 'wordpress') {
      addNewWordpressBLog(currentDetails, newBlogDetail)
    } else if (newBlogDetail?.name === 'blogspot') {
      addNewBlogSpot(currentDetails)
    }
  }

  const pauseBlog = (e: any) => {
    setLoading(true)
    try {
      BlogService.pausedHideBlog(e?.id, !e.is_paused, 'is_paused' as any).then((res) => {
        if (!e.is_paused) {
          NotifySuccess(t('Blog has been paused'))
        } else {
          NotifySuccess(t('Blog has been resumed'))
        }
        getBlogList()
      })
    } catch (error) {
      console.log('error')
      setLoading(false)
    }
  }

  const archiveBlog = (e: any) => {
    setLoading(true)
    try {
      BlogService.pausedHideBlog(e?.id, !e.is_hidden, 'is_hidden' as any).then((res) => {
        getBlogList()
      })
    } catch (error) {
      console.log('error')
      setLoading(false)
    }
  }

  const removeBlog = (e: any) => {
    //  setLoading(true)
    Swal.fire({
      title: t('Are you sure?'),
      text: t('Are you sure you want to delete?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Yes!'),
    }).then((result) => {
      if (result.value) {
        BlogService.deleteBlog(e?.id as any)
          .then((res) => {
            getBlogList()

            toast(t('Successfully deleted!'), {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: 'success',
            })
          })
          .catch((e: any) => {
            // setLoading(false)
            toast(t('Error occured!'), {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: 'error',
            })
          })
      }
    })
  }

  const saveBlogSettings = (updatedValues: any) => {
    try {
      if (updatedValues?.blogDetail?.type === 'wordpress') {
        BlogService.updateSettingsWordpressBlog(
          updatedValues?.blogDetail?.id as any,
          updatedValues as any
        ).then((res) => {
          getBlogList()
          setActiveBlogSettingsModal(!activeBlogSettingsModal)
          NotifySuccess(t('Settings updated sucessfully'))
        })
      } else if (updatedValues?.blogDetail?.type === 'blogspot') {
        BlogService.updateSettingsBloggerBlog(
          updatedValues?.blogDetail?.id as any,
          updatedValues as any
        ).then((res) => {
          getBlogList()
          NotifySuccess(t('Settings updated sucessfully'))
          setActiveBlogSettingsModal(!activeBlogSettingsModal)
        })
      } else if (updatedValues?.blogDetail?.type === 'quickblog') {
        BlogService.updateSettingsQuickBlog(
          updatedValues?.blogDetail?.id as any,
          updatedValues as any
        ).then((res) => {
          getBlogList()
          NotifySuccess(t('Settings updated sucessfully'))
          setActiveBlogSettingsModal(!activeBlogSettingsModal)
        })
      }
    } catch (error) {
      console.log('error settings')
      setLoading(false)
    }
    const currentBlogPath = window.location.pathname.search('web-whale')
    if (currentBlogPath < 1) {
      history.push('/web-whale')
    }
  }

  const openUrl = (url: string) => {
    window.open(url, '_blank')
  }

  const changePage = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleBlogRemainsControl = () => {
    if (currentConnectedBlog === userPlanBlogPermission && checkUnlimitedPlan) {
      Swal.fire({
        title: t("Your current plan doesn't support more blogs"),
        text: t(
          "Your current plan doesn't support more blogs, please consider upgrading your plan.."
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Go to plans'),
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: '/pages/profile/plans',
          })
        }
      })
    } else {
      setActiveNewBlogModal(!activeNewBlogModal)
    }
  }

  useEffect(() => {
    getBlogList()
  }, [currentPage])

  return [
    BEData,
    loading,
    blogList,
    totalData,
    currentPage,
    countPerPage,
    selectedBlog,
    archideBlogs,
    allCategories,
    addedNewlyBlog,
    activeNewBlogModal,
    closeModalWithError,
    selectedBloggerBlog,
    wordpressVerifyModal,
    selectedWordPressBlog,
    wordpressVerifyDetail,
    activeBlogSettingsModal,
    isSuccessfulConnectionModal,
    openUrl,
    setBEData,
    pauseBlog,
    addNewBlog,
    changePage,
    removeBlog,
    archiveBlog,
    getBlogPosts,
    saveBlogSettings,
    setActiveNewBlogModal,
    setCloseModalWithError,
    setWordpressVerifyModal,
    getAllWorpressCategories,
    handleBlogRemainsControl,
    setSelectedWordPressBlog,
    setActiveBlogSettingsModal,
    setIsSuccessfulConnectionModal,
    onAddQuickblog,
    setSelectedBloggerBlog,
  ]
}

export default useBlogHooks
