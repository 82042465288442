import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../_metronic/helpers'
import '../index.scss'

type Props = {
  show: boolean
  blogData?: any
  handleClose?: () => void
  onOpenNewBlogSettings?: (e: any) => void
}

const ConnectionSuccessfulModal: React.FC<Props> = ({
  show,
  blogData,
  handleClose,
  onOpenNewBlogSettings,
}) => {
  const {t} = useTranslation('translation')
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-between border-0'>
          <h3 className='text-success'>{t('Connection successful')}</h3>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-around'>
              {t('Your website')} {blogData?.data?.url}{' '}
              {t(
                'is now connected to myBlogs. Now you can complete the setup by changing the settings of that connection. (You can always change settings later)'
              )}
              <div className='horizontal__space'></div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end gap-3'>
          <button className='btn-sm fs-5' onClick={handleClose}>
            {t('Close')}
          </button>
          <button
            className='btn-sm btn-primary text-white fs-5'
            onClick={() => {
              onOpenNewBlogSettings && onOpenNewBlogSettings(blogData)
              handleClose && handleClose()
            }}
          >
            {t('Check connection settings')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {ConnectionSuccessfulModal}
