import React from 'react'
import '../index.scss'

export default function TableTag({onClick, ...props}: any) {
  const getBackground = () => {
    if (props.status === 'pause') {
      return 'paused-tag'
    } else if (props.status === 'hidden') {
      return 'hidden-tag'
    } else if (props.status === 'approved') {
      return 'approved-tag'
    } else if (props.status === 'irrelevant') {
      return 'paused-tag'
    } else {
      return 'whitelist-tag'
    }
  }

  const getTextColor = () => {
    if (props.status === 'pause') {
      return 'text-warning'
    } else if (props.status === 'hidden') {
      return 'text-danger'
    } else if (props.status === 'approved') {
      return 'text-success'
    } else if (props.status === 'irrelevant') {
      return 'text-warning'
    } else {
      return 'text-dark'
    }
  }

  return (
    <div className={`tableTag__body ${getBackground()} mb-0`} onClick={onClick}>
      <div className='d-flex align-items-center'>
        {props.large ? <h4 className={`${getTextColor()} mb-0  me-2`}>{props.tag}</h4> : props.tag}{' '}
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='mh-50px'
        >
          <path
            d='M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z'
            fill='currentColor'
          ></path>
          <path
            d='M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z'
            fill='currentColor'
          ></path>
        </svg>
      </div>
    </div>
  )
}
