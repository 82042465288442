import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  filters: {
    countries: [],
    entry: null,
    submissionType: null,
    extraFilter: {
      whitelisted: 'whitelistedWithout',
      irrelevant: 'irrelevantWithout',
      hidden: 'hiddenWithout',
      approved: 'approvedWithout',
      showOnlyMatches: true,
      latestItems: true,
    },
    searchParam: null,
  },
  loading: false,
  isCompareModalOpen: false,
  activeTab: 0,
  showUploader: false,
  files: [],
  textFiles: [],
  mapData: [],
  selectedItems: [],
  currentFinding: {},
  forceChangePage: false,
  totalData: 1,
  totalDataForTexts: 1,
  currentIndex: 0,
  countPerPage: 10,
  currentPage: 1,
  countPerPageForTexts: 10,
  externalHandleSubmission: '',
}

const montitoringFiltersSlice = createSlice({
  name: 'montitoringFilters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCompareModalOpen: (state, action) => {
      state.isCompareModalOpen = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setShowUploader: (state, action) => {
      state.showUploader = action.payload
    },
    setFiles: (state, action) => {
      state.files = action.payload
    },
    setTextFiles: (state, action) => {
      state.textFiles = action.payload
    },
    setMapData: (state, action) => {
      state.mapData = action.payload
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload
    },
    setCurrentFinding: (state, action) => {
      state.currentFinding = action.payload
    },
    setForceChangePage: (state, action) => {
      state.forceChangePage = action.payload
    },
    setTotalData: (state, action) => {
      state.totalData = action.payload
    },
    setTotalTextData: (state, action) => {
      state.totalDataForTexts = action.payload
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload
    },
    setCountPerPage: (state, action) => {
      state.countPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setCountPerPageForTextFiles: (state, action) => {
      state.countPerPageForTexts = action.payload
    },
    setExternalHandleSubmission: (state, action) => {
      state.externalHandleSubmission = action.payload
    },
  },
})

export const {
  setFilters,
  setLoading,
  setCompareModalOpen,
  setActiveTab,
  setShowUploader,
  setFiles,
  setTextFiles,
  setMapData,
  setSelectedItems,
  setCurrentFinding,
  setForceChangePage,
  setTotalData,
  setTotalTextData,
  setCurrentIndex,
  setCountPerPage,
  setCurrentPage,
  setCountPerPageForTextFiles,
  setExternalHandleSubmission,
} = montitoringFiltersSlice.actions

export default montitoringFiltersSlice.reducer
