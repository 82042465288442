import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../_metronic/helpers'
import '../index.scss'

export default function AccountPostModal({posts, show, handleClose}: any) {
  const {t} = useTranslation('translation')
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={() => {
        handleClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header d-flex justify-content-between p-0 border-0'>
          <h3>{t('Posts')}</h3>
          <div
            className='btn btn-icon btn-sm btn-light-primary'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div id='account_post-modal' className='h-25'>
          {posts?.length > 0 ? (
            <>
              {posts?.map((post: any, idx: number) => {
                return (
                  <>
                    <div className='border-bottom py-4 overflow-hidden'>
                      <a href={post?.permanent_url} target='_blank'>
                        {idx + 1} - {post?.permanent_url}
                      </a>
                    </div>
                  </>
                )
              })}
            </>
          ) : (
            <h3>{t('Dont have Any post')}</h3>
          )}
        </div>
      </div>
    </Modal>
  )
}
