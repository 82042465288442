import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import AccountPostModal from '../../../../../social-protect/components/AccountPostModal'
import AddNewSocialInfoModal from '../../../../../social-protect/components/AddNewSocialInfoModal'
import InstaTokenModal from '../../../../../social-protect/components/InstaTokenModal'
import SocialProtectPermissionModal from '../../../../../social-protect/components/SocialProtectPermissionModal'
import useSocialHooks from '../../../../../social-protect/hooks/useSocialHooks'
import '../../../../../social-protect/index.scss'
import ButtonWrapper from '../ButtonWrapper'

function SocialConnectAction() {
  // <======DONT REMOVE ANY PROPS======>
  const [
    data,
    posts,
    loading,
    totalData,
    currentPage,
    hideAccounts,
    countPerPage,
    instaTokenLink,
    showDetailModal,
    setShowDetailModal,
    showPermissionModal,
    isInstaTokenModalOpen,
    setShowPermissionModal,
    changePage,
    getDetails,
    hideAccount,
    pauseAccount,
    createAccount,
    deleteAccount,
    refreshAccount,
    setInstaTokenLink,
  ] = useSocialHooks()
  // <======DONT REMOVE ANY PROPS======>

  const [isOpen, setIsOpen] = useState(false)
  const [selectedSocial, setSelectedSocial] = useState('')
  const userInfo: any = useSelector((state: any) => state.user.userInfo)

  const handleSocialModal = (socialType: string) => {
    setSelectedSocial(socialType)
    if (userInfo?.data?.praxis?.data?.tier?.toro) {
      setIsOpen(true)
    } else {
      setShowPermissionModal(true)
    }
  }

  return (
    <>
      <ButtonWrapper
        id='plan_toro'
        planName='My Socials'
        title='Connect Facebook Account'
        icon='duotune/social/soc004.svg'
        onCreateAccount={() => {
          handleSocialModal('facebook')
        }}
      />
      <ButtonWrapper
        id='plan_toro'
        planName='My Socials'
        title='Connect Instagram Account'
        icon='duotune/social/soc005.svg'
        onCreateAccount={() => {
          handleSocialModal('instagram')
        }}
      />
      <AddNewSocialInfoModal
        show={isOpen}
        accountType={selectedSocial}
        onCreateAccount={(accountType: any) => createAccount(accountType)}
        handleClose={() => {
          setIsOpen(false)
          setSelectedSocial('')
        }}
      />
      <SocialProtectPermissionModal
        show={showPermissionModal}
        handleClose={() => {
          setShowPermissionModal(false)
        }}
      />
      <AccountPostModal
        show={showDetailModal}
        handleClose={() => setShowDetailModal(false)}
        posts={posts}
      />
      <InstaTokenModal
        isOpen={isInstaTokenModalOpen}
        link={instaTokenLink}
        onClose={() => {
          setShowPermissionModal(false)
          setInstaTokenLink(null)
        }}
      />
    </>
  )
}

export default SocialConnectAction
