import axios from 'axios'

const instance = axios.create({
  // baseURL: process.env.HOST_URL,
  baseURL: 'https://api.copyrightsworld.com',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
})
instance.interceptors.request.use(
  function (config) {
    const apiToken = !!localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token') || '{}' ) : ''
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${apiToken}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    var errorObj = {
      response: {message: ''},
    }
    if (error.response === undefined || error.response.status === 405) {
      errorObj.response.message = error.message.toString()
      return Promise.reject(errorObj)
    } else if (error.response.status === 401) {
      // store.dispatch({
      //   type: "LOGOUT",
      // })
      //err message will use from UnauthorizedPage comp
      // window.history.replaceState({ state: error.response.data.messageText }, "", "/401");
      // window.location.replace("/401");
    } else if (error.response.status === 403) {
      // window.location.replace("/403")
    } else if (error.response.status === 404) {
      // window.location.replace("/404")
    } else {
      return Promise.reject(error)
    }

    return
  }
)

export default instance
