import {AxiosResponse} from 'axios'
import axiosSettings from '../api-config/axiosSettings'

export class BadgeService {
  public static async badgeDetail(id: string): Promise<AxiosResponse> {
    try {
      return await axiosSettings.get<null, AxiosResponse>(`/v1/badge/${id}?include=submission`)
    } catch (error: any) {
      throw Object(error.response)
    }
  }
}
