import React from 'react'
import {useTranslation} from 'react-i18next'
import {KTSVG} from '../../../../../../../_metronic/helpers'

function ModalHeader({newBlogForm, onclose}: any) {
  const {t} = useTranslation('translation')
  return (
    <div className='modal-header py-2 d-flex justify-content-between border-0'>
      <h3>
        {t('Add New')} {newBlogForm === 'wordpress' && 'Wordpress'} {t('Blog')}
      </h3>
      <div className='btn btn-icon btn-sm btn-light-primary' onClick={onclose}>
        <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
    </div>
  )
}

export default ModalHeader
