import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

function ItemSection({link, path, title}: any) {
  return (
    <div
      className='d-flex w-100 align-items-center gap-3 cursor-pointer bg-hover-light-primary p-1'
      onClick={() => window.open(path)}
    >
      <img className='rounded ob' style={{width: '6rem', objectFit: 'cover'}} src={link} />
      <p className='fs-6 fw-bolder  text-dark mb-0'>{title}</p>
    </div>
  )
}

function UsefulLinksCard() {
  const {t} = useTranslation('translation')
  return (
    <div className='card'>
      <div className='fs-3 fw-bold card-header w-100 align-items-center p-5'>{t('Useful Links')}</div>
      <div className='card-body d-flex flex-column gap-2 p-5'>
        <ItemSection
          link={toAbsoluteUrl('/media/usefeulLinks/4.png')}
          path='https://hub.toroblocks.com/p/wiki/toro-kb'
          title='Knowledge base'
        />
        <ItemSection
          link={toAbsoluteUrl('/media/usefeulLinks/2.png')}
          path='https://hub.toroblocks.com/p/toroblocks-roadmap'
          title='Roadmap'
        />
        <ItemSection
          link={toAbsoluteUrl('/media/usefeulLinks/1.png')}
          path='https://www.toroblocks.com/toros-coin'
          title='Toros'
        />
        <ItemSection
          link={toAbsoluteUrl('/media/usefeulLinks/3.png')}
          path='https://www.toroblocks.com/creator-economy-network'
          title='CEN podcast'
        />
      </div>
    </div>
  )
}

export default UsefulLinksCard
