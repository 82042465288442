import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {NotifyError, NotifySuccess} from '../../../../app/Helpers/Toast'
import {ConfigModel} from '../../../../app/modules/auth/models/ConfigModel'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {AuthenticationServices} from '../../../../services/api_services/AuthenticationServices'
import {toast} from 'react-toastify'
interface IAuthState {
  user?: UserModel //will delete
  accessToken?: string
  config?: ConfigModel
  userInfo?: any
  userDelete?: boolean
  status: string
  registerStatus: string
  currentPage?: number
  toroCurrentPage?: number
  currentToroCard?: any
  whalePageNumber?: number
}

const initialState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  config: undefined,
  userInfo: undefined,
  status: 'idle',
  registerStatus: 'idle',
  userDelete: undefined,
  currentPage: undefined,
  toroCurrentPage: undefined,
  currentToroCard: undefined,
  whalePageNumber: 1,
}

const showAllErrors = (errors: any) => {
  let newErrs: any = []
  const keys = Object.keys(errors)
  keys.forEach((key) => {
    errors[key].forEach((value: any) => {
      if (value) {
        newErrs.push(value)
      }
    })
  })
  newErrs.map((e: any) => {
    toast(e, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: 'error',
    })
  })
}

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleDeleteUserRedux: (state, action) => {
      state.userDelete = action.payload
    },
    handlePaginationChange: (state, actiton) => {
      state.currentPage = actiton.payload
    },
    handleToroPaginationChange: (state, actiton) => {
      state.toroCurrentPage = actiton.payload
    },
    handleCurrentToroCard: (state, actiton) => {
      state.currentToroCard = actiton.payload
    },
    handleWhalePaginationChange: (state, actiton) => {
      state.whalePageNumber = actiton.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserInfo.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userInfo = action.payload
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed'
      })

      //register
      .addCase(handleRegisterService.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(handleRegisterService.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userInfo = action.payload
        NotifySuccess('You have registered succesffully! You will be redirected to login page.')
        setTimeout(() => {
          window.location.href = '/login'
        }, 3500)
      })
      .addCase(handleRegisterService.rejected, (state, action) => {
        state.status = 'failed'
        showAllErrors(action?.payload?.data?.errors)
      })

      //forget password

      .addCase(handleResetPassword.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(handleResetPassword.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userInfo = action.payload
        NotifySuccess('Sent password reset. Please check your email')
        setTimeout(() => {
          window.location.href = '/login'
        }, 3500)
      })
      .addCase(handleResetPassword.rejected, (state, action) => {
        state.status = 'failed'
        NotifyError('The login detail is incorrect')
      })
  },
})

export const fetchUserInfo: any = createAsyncThunk(
  '/v1/user/profile?include=options,profile,praxis,financial',
  async (queryParam: any, {getState, rejectWithValue}) => {
    const res = await AuthenticationServices.getAllUserInfo()
    return res.data
  }
)

export const handleRegisterService: any = createAsyncThunk(
  '/v1/register',
  async (queryParam: any, {getState, rejectWithValue}) => {
    return AuthenticationServices.register(
      queryParam.email,
      queryParam.firstname,
      queryParam.lastname,
      queryParam.country_id,
      queryParam.password,
      queryParam.accept_tos,
      queryParam.localization
    )
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return rejectWithValue(e)
      })
  }
)

export const handleResetPassword: any = createAsyncThunk(
  '/v1/password/forgot',
  async (queryParam: any, {getState}) => {
    const res = await AuthenticationServices.forgotPassword(queryParam)
    return res.data
  }
)

export const {
  handleDeleteUserRedux,
  handleWhalePaginationChange,
  handleCurrentToroCard,
  handlePaginationChange,
  handleToroPaginationChange,
} = userSlice.actions

export default userSlice.reducer
