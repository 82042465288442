import React from 'react'
import {useSelector} from 'react-redux'
import DashboardBody from './components'

function DashboardWrapper() {
  let currentUserData = useSelector((state: any) => state.user.userInfo)
  currentUserData = currentUserData?.data

  return <DashboardBody currentUserData={currentUserData} />
}

export default DashboardWrapper
