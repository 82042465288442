import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Swal from 'sweetalert2'
import {SocialService} from '../../../../services/api_services/SocialService'
import {
  handleCurrentToroCard,
  handleToroPaginationChange,
} from '../../../../setup/reduxslice/slices/user'
import {NotifyError, NotifyInfo, NotifySuccess} from '../../../Helpers/Toast'

const useSocialHooks = () => {
  const {t} = useTranslation('translation')
  const [allData, setAllData] = useState([]) as any
  const [data, setData] = useState([]) as any
  const [instaTokenLink, setInstaTokenLink] = useState(null)
  const [isInstaTokenModalOpen, setInstaTokenModal] = useState(false)
  const [hideAccounts, setHideAccounts] = useState([]) as any
  const [showDetailModal, setShowDetailModal] = useState(false) as any
  const [showPermissionModal, setShowPermissionModal] = useState(false) as any
  const userInfo: any = useSelector((state: any) => state.user.userInfo)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalData, setTotalData] = useState<number>(1)
  const [countPerPage, setCountPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()

  const [posts, setPosts] = useState([])

  const getData = async () => {
    await SocialService.getAccounts(currentPage).then((res: any) => {
      const currentAccounts = res?.data?.data
      const getHideAccounts = currentAccounts.filter((account: any) => account.is_hidden !== false)
      const getShowAccounts = currentAccounts.filter((account: any) => account.is_hidden === false)
      setAllData(currentAccounts)
      setHideAccounts(getHideAccounts)
      setData(getShowAccounts)
      setTotalData(res?.data?.meta?.pagination?.total)
      setCountPerPage(res?.data?.meta?.pagination?.per_page)
    })
  }

  const createAccount = async (type: string) => {
    const userPlanSocialProtectorPermission =
      userInfo?.data?.praxis?.data?.tier?.toro?.meta?.feature?.social
    const checkUnlimitedPlan = userInfo?.data?.praxis?.data?.tier?.toro?.limited
    const currentConnectedAccounts = data?.length
    if (currentConnectedAccounts === userPlanSocialProtectorPermission && checkUnlimitedPlan) {
      Swal.fire({
        title: t("Your current plan doesn't support more accounts"),
        text: t(
          "Your current plan doesn't support more accounts, please consider upgrading your plan.."
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Go to plans'),
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: '/pages/profile/plans',
          })
        }
      })
    } else {
      if (userInfo?.data?.praxis?.data?.tier?.toro) {
        try {
          await SocialService.createAccount(type).then((res) => {
            if (res) {
              window.location.href = res?.data?.url
              window.localStorage.setItem('newAccount', 'true')
            }
          })
        } catch (e) {
          NotifyError(t('Error occured!'))
        }
      } else {
        setShowPermissionModal(true)
      }
    }
  }

  const pauseAccount = async (x: any) => {
    setLoading(true)
    let value = '0'
    if (!x?.is_paused) value = '1'
    const res = await SocialService.pausedAccount(x?.id, value)
    if (!x.is_paused) {
      setLoading(false)
      NotifySuccess(t('Account has been paused'))
    } else {
      setLoading(false)
      NotifySuccess(t('Account has been resumed'))
    }
    const currentId = res?.data?.data?.id
    if (currentId) {
      const currentIdx = data.findIndex((x: any) => x?.id === currentId)
      if (currentIdx > -1) {
        const newData = [...data] as any
        newData[currentIdx].is_paused = res?.data?.data?.is_paused
        setData(newData)
      }
    }
  }

  const hideAccount = async (x: any) => {
    setLoading(true)
    let value = '0'
    if (!x?.is_hidden) value = '1'
    const res = await SocialService.hideAccount(x?.id, value)
    if (x.is_hidden) {
      setLoading(false)
      NotifySuccess(t('The account has been removed from the archive'))
    } else {
      setLoading(false)
      NotifySuccess(t('The account has been sent to archive'))
    }
    const currentId = res?.data?.data?.id
    if (currentId) {
      const currentIdx = allData.findIndex((x: any) => x?.id === currentId)
      if (currentIdx > -1) {
        const newData = [...allData] as any
        newData[currentIdx].is_hidden = res?.data?.data?.is_hidden
        setHideAccounts(newData.filter((account: any) => account.is_hidden !== false))
        setData(newData.filter((account: any) => account.is_hidden === false))
      }
    }
  }

  const deleteAccount = async (x: any) => {
    Swal.fire({
      title: t('Are you sure delete social account?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Delete Account'),
    }).then((result) => {
      if (result.value) {
        setLoading(true)
        try {
          SocialService.DeleteAccount(x?.id).then((res) => {
            if (res?.status === 200) {
              NotifyInfo(t('Account Deleted'))
              const newData = [...data].filter((a: any) => a?.id !== x?.id)
              setData(newData)
              setLoading(false)
            } else {
              NotifyError(t('Error occured!'))
              setLoading(false)
            }
          })
        } catch (err) {
          NotifyError(t('Error occured!'))
          setLoading(false)
        }
      }
    })
  }

  const refreshAccount = async (account: any) => {
    setLoading(true)
    try {
      const isItFacebook = account?.social?.data?.object === 'ToroFacebook'
      await SocialService.refreshAccount(account).then((res) => {
        if (res.status === 200) {
          if (res?.data?.refresh === 'error' && !isItFacebook) {
            setInstaTokenModal(true)
            setInstaTokenLink(res?.data?.url)
          } else {
            window.location.href = res?.data?.url
            NotifySuccess(t('Refresh completed'))
          }
          setLoading(false)
        } else {
          setLoading(false)
          console.log(res)
          NotifyError(t('Error occured!'))
        }
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
      NotifyError(t('Error occured!'))
    }
  }

  const getDetails = async (x: any, params?: any) => {
    dispatch(handleCurrentToroCard(x))
    await SocialService.GetPosts(x?.id, params).then((res) => {
      setPosts(res?.data?.data)
      setShowDetailModal(true)
    })
  }

  const changePage = (pageNumber: number) => {
    dispatch(handleToroPaginationChange(pageNumber))
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const currentPath = window.location.href
    const currentRes = currentPath.split('?')[1]
    if (currentRes) {
      if (currentRes.search('true') > 0 && window.localStorage.newAccount === 'true') {
        NotifySuccess(t('Account has been created. Please change connection settings'))
        setTimeout(() => {
          localStorage.removeItem('newAccount')
        }, 5000)
      }
    }
  }, [])
  return [
    data,
    posts,
    loading,
    totalData,
    currentPage,
    hideAccounts,
    countPerPage,
    instaTokenLink,
    showDetailModal,
    setShowDetailModal,
    showPermissionModal,
    isInstaTokenModalOpen,
    setShowPermissionModal,
    changePage,
    getDetails,
    hideAccount,
    pauseAccount,
    createAccount,
    deleteAccount,
    refreshAccount,
    setInstaTokenLink,
  ]
}

export default useSocialHooks
