import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'

export default function ImageModal({show, type, data, handleClose}: any) {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={() => {
        handleClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header d-flex justify-content-between p-0 border-0'>
          <h3>{type}</h3>
          <div
            className='btn btn-icon btn-sm btn-light-primary'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <img src={data} width='100%' height='100%' />
      </div>
    </Modal>
  )
}
