import {CHANGE_LOCALE, useLocaleContext} from './localization'

const SWITCH_LOCAL = {
  en: 'en',
  es: 'es',
  el: 'el',
}
const useLocale = () => {
  const [locale, dispatch] = useLocaleContext()

  const toggleLocale = () => {
    const newLocale = SWITCH_LOCAL[locale]
    dispatch({
      type: CHANGE_LOCALE,
      payload: {locale: newLocale},
    })
  }

  const getLocale = () => {
    return locale
  }

  const setLocale = (newLocale) => {
    dispatch({
      type: CHANGE_LOCALE,
      payload: {locale: newLocale},
    })
  }
  return {locale, toggleLocale, setLocale, getLocale}
}
export default useLocale
