import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-http-backend'
import common_en from './locales/en/translation.json'
import common_es from './locales/es/translation.json'
import common_el from './locales/el/translation.json'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    resources: {
      en: {
        translation: common_en, // 'common' is our custom namespace
      },
      es: {
        translation: common_es,
      },
      el: {
        translation: common_el,
      },
    },
    fallbackLng: 'en',
    preload: ['en', 'es', 'el'],
    supportedLngs: ['en', 'es', 'el'],
    keySeparator: false, // we do not use keys in form messages.welcome
    missingKeyNoValueFallbackToKey: 'unknownError',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
