import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import ModalCloseButton from './components/ModalCloseButton'
import ProtectedPostWrapper from './components/ProtectedPostWrapper'
import RadioInputSection from './components/RadioInputSection'
import SettingsModalHeader from './components/SettingsModalHeader'
import SwitchInputsWrapper from './components/SwitchInputsWrapper'

export default function BlogSettingsModal({
  show,
  wordpressBlogDetail,
  blogerBlogDetail,
  categories,
  handleClose,
  saveSettingsCallBack,
}: any) {
  const {t} = useTranslation('translation')
  const [selectedValues, setSelectedValues] = useState({} as any)
  const [newCategories, setNewCategories] = useState([] as any)

  const handleFirstLoadCategories = () => {
    let arr: any = []
    if (wordpressBlogDetail?.blog?.data?.settings['fetch_type'] === 'categories') {
      Object.keys(wordpressBlogDetail?.blog?.data?.settings?.categories).forEach((categoryId) =>
        arr.push({
          object: 'wp_category',
          id: parseInt(categoryId),
          name: wordpressBlogDetail?.blog?.data?.settings?.categories[categoryId],
        })
      )
    }
    setNewCategories(arr)
  }

  const addNewCategoryToSelectedValues = () => {
    if (wordpressBlogDetail?.id) {
      selectedValues['blogDetail'] = wordpressBlogDetail
      selectedValues['newCategories'] = newCategories
    } else {
      selectedValues['blogDetail'] = blogerBlogDetail
    }
    saveSettingsCallBack(selectedValues)
  }

  useEffect(() => {
    if (wordpressBlogDetail?.id) {
      setSelectedValues(wordpressBlogDetail?.blog?.data?.settings)
      handleFirstLoadCategories()
    } else if (blogerBlogDetail?.id) {
      setSelectedValues(blogerBlogDetail?.blog?.data?.settings)
    }
  }, [wordpressBlogDetail, blogerBlogDetail])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={() => {
        handleClose()
      }}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-between border-0'>
          <SettingsModalHeader
            wordpressBlogDetail={wordpressBlogDetail}
            blogerBlogDetail={blogerBlogDetail}
          />
          <ModalCloseButton onHandleClose={handleClose} onSelectedValues={setSelectedValues} />
        </div>
        <div className='modal-body bg-light-primary rounded'>
          <ProtectedPostWrapper
            categories={categories}
            newCategories={newCategories}
            selectedValues={selectedValues}
            wordpressBlogDetail={wordpressBlogDetail}
            onNewCategories={setNewCategories}
            onSelectedValues={setSelectedValues}
          />
          <div className='border-bottom border-secondary mb-4 pb-4'>
            <h4>{t('Get media from posts or just text ?')}</h4>
            <RadioInputSection
              title={t('Media & text')}
              name='handle_type'
              checked={selectedValues && selectedValues['handle_type'] === 'with_media' && true}
              onChange={(e: any) => {
                setSelectedValues({...selectedValues, handle_type: 'with_media'})
              }}
            />
            <RadioInputSection
              title={t('Media & text')}
              name='handle_type'
              checked={selectedValues && selectedValues['handle_type'] === 'only_text' && true}
              onChange={(e: any) => {
                setSelectedValues({...selectedValues, handle_type: 'only_text'})
              }}
            />
          </div>
          <SwitchInputsWrapper
            blogerBlogDetail={blogerBlogDetail}
            selectedValues={selectedValues}
            onSelectedValues={setSelectedValues}
          />
        </div>
        <div className='modal-footer'>
          <button
            className='btn btn-primary'
            onClick={() => {
              addNewCategoryToSelectedValues()
            }}
          >
            {t('Save Changes')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
