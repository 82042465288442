/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import WorldMap from 'react-svg-worldmap'
import '../index.scss'

export default function LiveMonitoringMap({data, onClick}: any) {
  const [activeCountry, setActiveCountry] = useState(null)

  return (
    <div className='map__container'>
      <WorldMap
        color='#9A73B8'
        value-suffix='people'
        data={data}
        valuePrefix='Prefix'
        valueSuffix='Suffix'
        onClickFunction={(obj: any) => {
          setActiveCountry(obj.countryCode)
          onClick(obj)
        }}
        tooltipTextFunction={(context) => {
          return `${context?.countryValue} matches in ${context?.countryName} `
        }}
      />
    </div>
  )
}
