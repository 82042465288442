import React from 'react'

function RadioInputSection({title, name, checked, onChange}: any) {
  return (
    <span className='form-check form-check-custom form-check-solid mb-3'>
      <input
        className='form-check-input'
        style={{marginRight: 10}}
        type='radio'
        checked={checked}
        name={name}
        onChange={onChange}
      />
      {title}
    </span>
  )
}

export default RadioInputSection
