import React from 'react'
import {useTranslation} from 'react-i18next'

import BlogConnectAction from './components/BlogConnectAction'
import FileUploadAction from './components/FileUploadAction'
import SocialConnectAction from './components/SocialConnectAction'

export default function ActionsCard({userDetails}: any) {
  const {t} = useTranslation('translation')
  const currentPlans = userDetails?.praxis?.data ? userDetails?.praxis?.data : null

  function isItInvalidPlan(planName: any) {
    return currentPlans?.tier[planName] ? false : true
  }

  return (
    <div className='card'>
      <div className='fs-3 fw-bold card-header w-100 align-items-center'>{t('Basic Actions')}</div>
      <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center gap-1'>
        <FileUploadAction isItInvalidPlan={isItInvalidPlan('copyright')} />
        <BlogConnectAction />
        <SocialConnectAction />
      </div>
    </div>
  )
}
