import React from 'react'
import {useHistory} from 'react-router'

import BlogSettingsModal from '../../../../../blog-passport/components/BlogSettingsModal/index'
import {ConnectionSuccessfulModal} from '../../../../../blog-passport/components/ConnectionSuccessfulModal'
import {NewBlogModal} from '../../../../../blog-passport/components/NewBlogModal'

import ProcessingModal from '../../../../../blog-passport/components/ProcessingModal'
import VerifyModal from '../../../../../blog-passport/components/VerifyModal'
import useBlogHooks from '../../../../../blog-passport/hooks/useBlogHooks'
import ButtonWrapper from '../ButtonWrapper'

function BlogConnectAction() {
  // =======DONT DELETE ANYTHING=======
  const [
    BEData,
    loading,
    blogList,
    totalData,
    currentPage,
    countPerPage,
    selectedBlog,
    archideBlogs,
    allCategories,
    addedNewlyBlog,
    activeNewBlogModal,
    closeModalWithError,
    selectedBloggerBlog,
    wordpressVerifyModal,
    selectedWordPressBlog,
    wordpressVerifyDetail,
    activeBlogSettingsModal,
    isSuccessfulConnectionModal,
    openUrl,
    setBEData,
    pauseBlog,
    addNewBlog,
    changePage,
    removeBlog,
    archiveBlog,
    getBlogPosts,
    saveBlogSettings,
    setActiveNewBlogModal,
    setCloseModalWithError,
    setWordpressVerifyModal,
    getAllWorpressCategories,
    handleBlogRemainsControl,
    setSelectedWordPressBlog,
    setActiveBlogSettingsModal,
    setIsSuccessfulConnectionModal,
    onAddQuickblog,
    setSelectedBloggerBlog,
  ] = useBlogHooks()
  // =======DONT DELETE ANYTHING=======

  const history = useHistory()

  return (
    <>
      <ButtonWrapper
        id='plan_whale'
        planName='My Blogs'
        title='Connect New Blog'
        icon='duotune/arrows/arr036.svg'
        onCreateAccount={handleBlogRemainsControl}
      />
      <NewBlogModal
        show={activeNewBlogModal}
        defaultPositionTrigger={closeModalWithError}
        BEData={BEData}
        handleClose={() => setActiveNewBlogModal(!activeNewBlogModal)}
        onDefaultCallBack={(e: any) => setCloseModalWithError(e)}
        onPluginInstall={() => {
          openUrl(BEData?.url as string)
          setBEData({})
        }}
        addNewBlockCallBack={(e: any) => {
          addNewBlog(e)
        }}
        onAddQuickblog={onAddQuickblog}
      />
      <ProcessingModal loading={loading} />
      <BlogSettingsModal
        show={activeBlogSettingsModal}
        wordpressBlogDetail={selectedWordPressBlog}
        blogerBlogDetail={selectedBloggerBlog}
        categories={allCategories}
        handleClose={() => setActiveBlogSettingsModal(!activeBlogSettingsModal)}
        saveSettingsCallBack={(updatedValues: any) => saveBlogSettings(updatedValues)}
      />
      <VerifyModal
        verifyData={wordpressVerifyDetail}
        show={wordpressVerifyModal}
        verifyBlogCallBack={(newBlogDetail: any) => addNewBlog(newBlogDetail)}
        handleClose={() => setWordpressVerifyModal(false)}
      />
      <ConnectionSuccessfulModal
        show={isSuccessfulConnectionModal}
        blogData={addedNewlyBlog}
        handleClose={() => {
          setIsSuccessfulConnectionModal(false)
        }}
        onOpenNewBlogSettings={(e: any) => {
          if (e.type === 'wordpress') {
            setSelectedWordPressBlog(e)
          } else {
            setSelectedBloggerBlog(e)
          }
          setActiveBlogSettingsModal(true)
        }}
      />
    </>
  )
}

export default BlogConnectAction
