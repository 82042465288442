import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../helpers'
import OnBoardingModalContent from '../onBoarding/onBoardingVideo/onBoardingModalContent'

export default function Help({}) {
  const [isOpen, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          marginRight: '10px',
          position: 'relative',
        }}
      >
        <div
          onClick={() => {
            setOpen(true)
          }}
        >
          <KTSVG path='/media/icons/duotune/custom/help.svg' className='svg-icon-2x' />
        </div>
      </div>
      <Modal
        id='move-to-folder-modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-1000px h-auto'
        show={isOpen}
        onHide={onClose}
      >
        <OnBoardingModalContent onClose={onClose} onDontShowAgain={() => setOpen(false)} />
      </Modal>
    </>
  )
}
