import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import MiniPlanCard from '../MiniPlanCard'

export default function ProfileCardPlans({userDetails}: any) {
  const {t} = useTranslation('translation')
  const [activePlanLength, setActivePlanLength] = useState(0)

  const plans = [{name: 'copyright'}, {name: 'defender'}, {name: 'whale'}, {name: 'toro'}]

  const currentPlans = userDetails?.praxis?.data ? userDetails?.praxis?.data : null

  function getActiveplans() {
    const proPlans = plans.filter(
      (x: any) =>
        currentPlans?.limits[x.name]?.is_trial === false &&
        currentPlans?.limits[x.name]?.is_trial?.can?.remains !== 0
    )
    setActivePlanLength(proPlans.length)
  }

  function TrialText() {
    return (
      <div className='border p-3 rounded fs-5'>
        {t('Your 30 days trial has expired.')}
        <span
          style={{textDecoration: 'underline'}}
          className='text-primary fw-bold ms-2 cursor-pointer'
          onClick={() => window.location.replace('/pages/profile/plans')}
        >
          {t('Please click here ')}
        </span>
        <span>{t('to upgrade your account')}</span>
      </div>
    )
  }

  useEffect(() => {
    getActiveplans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  return (
    <div className='d-flex flex-column gap-4 w-100'>
      {activePlanLength !== 0 ? (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem'}}>
          {plans.map((plan, idx) => {
            return (
              <MiniPlanCard
                key={idx}
                currentPlans={userDetails?.praxis?.data}
                planName={plan?.name}
              />
            )
          })}
        </div>
      ) : (
        <TrialText />
      )}
    </div>
  )
}
