import React from 'react'
import {ToastContainer} from 'react-toastify'

import HelpStoriesCard from './HelpStoriesCard'
import ProfileCard from './ProfileCard'
import ActionsCard from './ActionsCard'
import MonitoringCard from './MonitoringCard'
import ProtectedFilesCard from './ProtectedFilesCard'
import UsefulLinksCard from './UsefulLinksCard'

export default function DashboardBody({currentUserData}: any) {
  return (
    <div className='container'>
      <ToastContainer />
      <div className='row'>
        <div className='col-12 col-xl-3'>
          <ProfileCard userDetails={currentUserData} />
        </div>
        <div className='col-12 col-xl-6 d-flex flex-column gap-6 mt-5 mt-md-5 mt-lg-0'>
          <ActionsCard userDetails={currentUserData} />
          <MonitoringCard userDetails={currentUserData} />
          <ProtectedFilesCard userDetails={currentUserData} />
        </div>
        <div className='col-12 col-xl-3 mt-md-5 mt-lg-0  d-flex flex-column gap-6'>
          <HelpStoriesCard />
          <UsefulLinksCard />
        </div>
      </div>
    </div>
  )
}
