import {useState} from 'react'
import {KTSVG} from '../../../helpers'
import {SidePanel} from './SidePanel'

export default function News({}) {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          marginRight: '10px',
          position: 'relative',
        }}
      >
        <div
          onClick={() => {
            setOpen(!isOpen)
          }}
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className='svg-icon-2x' />
        </div>
      </div>
      {isOpen && (
        <SidePanel
          sidePanelToogle={isOpen}
          onClose={() => {
            setOpen(false)
          }}
        ></SidePanel>
      )}
    </>
  )
}
